.contract-list-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .contract-list-toolbar-actions {
        display: flex;

        > * {
            display: flex;
            align-items: center;
        }
    }

    .contract-list-toolbar-bulk-actions {
        display: none;
        margin-right: 15px;

        > * {
            margin: 0 5px;
        }

        &.on {
            .bulk-actions-toggle {
                background: white;

                .bulk-actions-label {
                    color: $bluechaos-5;
                }

                .toggle-icon {
                    color: $bluechaos-5;
                }
            }
        }

        .bulk-actions-toggle {
            border: 1px solid $battlegrey-3;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            height: 28px;
            padding: 0 15px 0 13px;
            white-space: nowrap;

            > * {
                align-self: center;
            }

            .bulk-actions-label {
                color: $battlegrey-6;
                margin-left: 5px;
            }

            .toggle-icon {
                color: $battlegrey-4;
                font-size: 2.5em;
            }
        }

        .bulk-action-btn {
            background: $bluechaos-5;
            border: 1px solid $bluechaos-5;
            border-radius: 4px;
            color: white;
            cursor: pointer;
            line-height: 26px;
            padding: 0 10px;

            &.disabled {
                background: $battlegrey-3;
                border: 1px solid $battlegrey-3;
                color: $battlegrey-6;
                cursor: not-allowed;
            }

            .bulk-action-btn-text {
                vertical-align: middle;
                white-space: nowrap;

                .bulk-action-count {
                    vertical-align: middle;
                }

                .bulk-action-icon {
                    font-size: 1.5em;
                    vertical-align: middle;
                }

                &.hide-on-mobile {
                    display: none;
                }
            }
        }
    }

    .contract-list-toolbar-filters {
        display: flex;
        flex-direction: row;
        align-items: center;

        .contract-list-toolbar-filter-title {
            margin-right: 10px;
            color: $battlegrey-10;
        }

        .contract-list-toolbar-filter-search {
            margin-left: 24px;
            color: $bluechaos-5;

            .contract-list-toolbar-icon {
                cursor: pointer;
            }
        }
    }
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
}

@media only screen and (min-width: $screen-md) {
    .contract-list-toolbar {
        .contract-list-toolbar-bulk-actions {
            display: flex;

            .bulk-action-btn {
                .bulk-action-btn-text {
                    display: none;

                    &.hide-on-mobile {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
