@use '@thought-river/ui-components/dist/colors.scss' as *;

.homePage {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 24px;
    overflow: hidden;
    padding: 24px 0 0 0;

    .toolbar {
        font-weight: 700;
        font-size: 16px;
        line-height: 34px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 24px;

        .toolbarFilters {
            display: flex;
            flex-wrap: wrap;
            column-gap: 24px;
            row-gap: 8px;

            .streamsSelect {
                width: 200px;
            }
        }
    }
}
