@use '@thought-river/ui-components/dist/colors.scss' as *;

.sidebar {
    width: 250px;
    border-right: 1px solid $battlegrey-4;
    padding: 24px 0 24px 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
    transition: width 0.2s;

    &.collapsed {
        width: 56px;

        & + .expandButtonWrapper {
            .expandButtonIcon {
                transform: rotate(180deg);
            }
        }
    }

    .newReportButtonWrapper {
        padding: 0px 24px;
        min-height: 30px;
        display: flex;
        align-items: center;
    }
}

.expandButtonWrapper {
    position: relative;
    width: 0;
    height: 0;

    .expandButton {
        position: absolute;
        border: 1px solid $battlegrey-4;
        border-radius: 16px;
        background: white;
        top: 30px;
        right: -10px;
        z-index: 999;

        .expandButtonIcon {
            transition: transform 0.4s;
        }
    }
}
