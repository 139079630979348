@use '@thought-river/ui-components/dist/colors.scss' as *;

.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px;
    height: calc(100% - 50px);

    .actionsBar {
        display: flex;
        gap: 8px;
        color: $font-color;
        align-items: center;
    }
}
