@use '@thought-river/ui-components/dist/colors.scss' as *;

.custom-color-picker {
    background: white;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 4px 8px;

    .color-row {
        display: flex;
        flex-wrap: nowrap;

        .color-option-wrapper {
            border: 1px solid transparent;
            border-radius: 5px;
            box-sizing: border-box;
            cursor: pointer;
            display: inline-block;
            height: 26px;
            width: 26px;
            margin: 2px;
            padding: 1px;

            &:hover {
                border: 1px solid $battlegrey-4;
            }
        }

        .color-option {
            border: 1px solid rgba(23, 43, 77, 0.12);
            border-radius: 4px;
            display: inline-block;
            height: 20px;
            width: 20px;
        }
    }

    .color-picker-tabs {
        margin-bottom: 4px;
        min-height: 30px;
    }
}
