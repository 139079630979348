.contract-container {
    background: white;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    min-height: 0;

    .contract-panes {
        flex-grow: 1;
        height: 0;
    }

    .contract-wrapper {
        background: $battlegrey-2;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        overflow-y: auto;

        .contract {
            display: flex;
            flex-grow: 1;
            flex-direction: row;
            margin: 0 auto;
            max-width: 1140px;

            .contract-body-wrapper {
                display: flex;
                flex-direction: column;
                flex: 1 0;
                position: relative;

                .contract-body {
                    height: 0;
                    flex: 1 1 auto;
                    position: relative;

                    table {
                        background: white;
                        border-collapse: collapse;
                        border-spacing: 0;
                        margin: 0 auto;
                        table-layout: fixed;
                        width: calc(100% - 60px);

                        td {
                            background: white;
                            border: 1px solid $battlegrey-7;

                            &[rowspan] {
                                height: 1px;
                            }
                        }
                    }
                }
            }
        }
    }

    .processing-alert,
    .reprocessing-alert {
        background: white;
        bottom: 25px;
        color: white;
        padding: 8px 12px;
        position: absolute;
        right: 25px;
        border: 1px solid $battlegrey-6;
        border-radius: 4px;
        z-index: 2000;

        .loader-wrapper {
            color: $battlegrey-7;
            height: 34px;
            line-height: 34px;

            > * {
                vertical-align: middle;
            }

            .loader {
                height: 15px;
                vertical-align: middle;
                width: 15px;
                border-color: $vintagevelvet-1;
                border-left-color: $bluechaos-5;
            }

            .loader-text {
                color: $battlegrey-7;
                margin-left: 5px;
            }
        }
    }

    .processing-alert {
        width: 290px;

        .loader-wrapper {
            color: $battlegrey-7;
            height: auto;
            line-height: 1;

            .loader-text {
                margin-bottom: 8px;
            }
        }
    }

    .heatmap-upload-progress {
        bottom: 24px;
        position: absolute;
        right: 24px;
        z-index: 2000;
    }

    .deal-type-error {
        .deal-type-error-icon {
            color: $bluechaos-5;
        }

        .deal-type-error-icon {
            font-size: 2.14rem;
        }

        .deal-type-error-message {
            margin-top: 20px;
        }

        .deal-type-error-title {
            margin-top: 15px;
            font-size: 1.42rem;
        }

        .deal-type-error-stream {
            font-weight: bold;
        }
    }
}

.risks-section {
    margin-bottom: 10px;
    position: relative;

    .expandable-tab {
        color: $bluechaos-5;
        cursor: pointer;
        display: flex;
        user-select: none;

        .expandable-tab-toggle-icon {
            margin-top: -4px;
        }

        .expandable-tab-label {
            margin-left: 10px;
            position: relative;
        }
    }

    .missing-risks {
        margin-left: 35px;
        margin-top: 10px;
        max-height: 158px;
        overflow: auto;
    }
}

.split {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: calc(830px + 525px + 2px); // panes min width + gutter
    height: 100%;

    .gutter {
        cursor: col-resize;
        background: #ccc;

        &:hover {
            background: $maastrichtblue-7;
        }
    }
}
