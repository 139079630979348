@use '@thought-river/ui-components/dist/colors.scss' as *;
@import '../../styles/variables.scss';

.wizard-file-uploader-wrapper {
    text-align: center;

    &.error {
        .wizard-file-uploader-dropzone {
            .wizard-file-uploader {
                background-color: $bittenred-2;
                @include dashed-border-error;
            }
        }

        .wizard-file-uploader-error {
            &.show {
                display: block;
                margin-top: 5px;
            }
        }

        .multiple-files-error {
            &.show {
                display: block;
                margin-top: 5px;
            }
        }
    }

    .wizard-file-uploader-dropzone {
        .wizard-file-uploader {
            @include dashed-border;
            align-items: center;
            border-radius: 4px;
            color: $vintagevelvet-8;
            position: relative;

            &.dragging {
                @include dashed-border-highlighted;

                .dropzone-label {
                    visibility: hidden;
                }

                .wizard-file-uploader-drop-label {
                    display: flex;
                }
            }

            .wizard-file-uploader-input {
                height: 1px;
                opacity: 0;
                position: absolute;
                width: 1px;
                visibility: hidden;
            }

            .wizard-file-uploader-elements {
                display: flex;
                justify-content: center;

                .wizard-file-uploader-element {
                    align-self: center;
                    margin: 0 12px;

                    .wizard-file-uploader-button {
                        align-items: center;
                        display: flex;
                        padding: 6px 12px 6px 8px;

                        & > span {
                            margin-left: 8px;
                        }
                    }
                }
            }

            .wizard-file-uploader-drop-label {
                display: none;
                justify-content: center;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);

                > * {
                    align-self: center;
                }

                .wizard-file-uploader-drop-label-icon {
                    font-size: 1.5em;
                    line-height: 1.5em;
                    vertical-align: middle;
                }

                .wizard-file-uploader-drop-label-text {
                    line-height: 1.5em;
                    margin-left: 7px;
                    vertical-align: middle;
                }
            }
        }

        .file-list-preview-wrapper {
            position: relative;

            .file-list-preview {
                border: 1px solid $battlegrey-5;
                border-radius: 4px;
                height: 110px;
                overflow-y: auto;

                .file-preview-table {
                    border-collapse: collapse;
                    width: 100%;

                    tr:nth-child(2n) {
                        td {
                            background-color: $battlegrey-2;
                        }
                    }

                    td {
                        padding: 8px 12px;
                        color: $battlegrey-7;
                    }

                    .file-name-cell {
                        overflow: hidden;
                        max-width: 250px;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        .file-type-img {
                            height: auto;
                            margin-right: 10px;
                            max-height: 15px;
                            max-width: 16px;
                            vertical-align: middle;
                            width: auto;
                        }

                        .file-name {
                            vertical-align: middle;
                        }
                    }

                    .btn-primary {
                        padding: 3px 4px;
                    }

                    .text-right {
                        text-align: right;
                    }

                    .upload-status-cell {
                        .loader-wrapper {
                            height: 20.5px;
                            width: 20.5px;
                            display: inline-flex;
                            justify-content: flex-end;
                            vertical-align: middle;

                            .loader {
                                height: 15px;
                                width: 15px;
                                align-self: center;
                                vertical-align: middle;
                            }
                        }

                        .upload-status-icon {
                            height: 20px;
                            width: 20px;
                            color: $success-color;
                            vertical-align: middle;

                            &.failed {
                                color: $error-color;
                            }
                        }
                    }
                }
            }

            .file-preview-info {
                bottom: -22px;
                color: $battlegrey-7;
                display: flex;
                font-size: 0.9em;
                position: absolute;
                right: 14px;

                .reset-btn-wrapper {
                    border-right: 1px solid $battlegrey-5;
                    margin-right: 10px;
                    padding-right: 10px;

                    .reset-btn {
                        color: $bluechaos-5;
                        cursor: pointer;

                        .reset-btn-icon {
                            font-size: 1em;
                            margin-right: 2px;
                            vertical-align: middle;
                        }

                        .reset-btn-text {
                            vertical-align: middle;
                        }
                    }
                }
            }
        }

        .dropzone-label {
            cursor: pointer;
            padding: 20px 30px 25px;

            .dropzone-icon {
                color: $bluechaos-5;
                font-size: 2.55em;
            }

            .dropzone-text {
                margin-top: 5px;
            }

            .dropzone-subtext {
                font-size: 12px;
                margin-top: 10px;
            }
        }
    }

    .wizard-file-uploader-error {
        display: none;
        color: $bittenred-8;
    }
    .multiple-files-error {
        display: none;
        color: $bittenred-8;
    }
}
