@use '@thought-river/ui-components/dist/colors.scss' as *;

.title {
    padding: 0 16px;
}

.item {
    height: 38px;
    line-height: 22px;
    padding: 0 16px 0 16px;
    cursor: pointer;
    border-radius: 4px 0px 0px 4px;
    justify-content: space-between;
    gap: 8px;
    display: flex;
    align-items: center;

    &.itemSelected {
        background-color: $bluechaos-1;
        border-right: 3px solid $bluechaos-7;

        .overflowMenuButton {
            left: 3px;
        }
    }

    .itemLabel {
        height: 100%;
        width: 100%;
        display: flex;
        gap: 8px;
        min-width: 0;
        align-items: center;
    }

    &:hover {
        background-color: $bluechaos-1;

        .overflowMenuButton {
            visibility: visible;
            max-width: none;
        }
    }

    .link {
        text-decoration: none;
        color: $font-color;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 38px;
        width: 100%;
    }

    .overflowMenuButton {
        visibility: hidden;
        max-width: 0;
    }
}

.noItemsMessage {
    padding: 12px 16px;
}
