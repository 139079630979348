.issue-list-pane {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 0;
    width: 100%;

    .contents-wrapper {
        display: flex;
        flex-direction: row;
        flex: 1 1;
        min-height: 0;
    }

    .intelligence-bars-wrapper {
        padding: 10px 20px;
        border-bottom: 1px solid $vintagevelvet-1;
        box-sizing: border-box;

        .resolved-issues-progress {
            color: $vintagevelvet-6;
            font-size: 12px;
            line-height: 20px;
            float: right;
            margin-top: -5px;

            .resolved-issues-ratio {
                margin-right: 5px;
            }
        }
    }

    .filters-toolbar {
        display: flex;
        justify-content: space-between;
        flex-shrink: 0;
        text-align: left;
        border-bottom: 1px solid $vintagevelvet-1;
        align-items: center;

        .issue-views-dropdown {
            min-width: max-content;
            width: 150px;
        }

        .right-aligned-elements {
            display: flex;

            .filters-button-wrapper {
                align-self: center;
                height: 24px;
                position: relative;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .filters-button {
                    .active-filters-indicator {
                        margin-left: 2px;
                    }
                }
            }
        }
    }

    .issue-list {
        position: relative;
        color: $battlegrey-7;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        gap: 12px;
        padding: 0 12px;

        .info-card {
            margin-top: 12px;
            width: 100%;

            .MuiCardContent-root {
                cursor: pointer;
            }

            .issue {
                color: $battlegrey-10;
                display: flex;
                flex-direction: column;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .description {
                    color: $battlegrey-6;
                    font-size: 0.85em;
                    margin-top: 5px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .details {
                    display: flex;
                    font-size: 0.9em;
                    margin-bottom: 5px;

                    > * {
                        align-self: flex-end;
                    }

                    .details-group {
                        display: flex;
                        flex: 1 1 auto;
                        position: relative;

                        .clauses {
                            display: flex;
                            flex-wrap: wrap;
                            padding-top: 2.5px;

                            .clause-number {
                                align-self: center;
                                color: $vintagevelvet-8;
                                cursor: pointer;
                                margin-left: 10px;
                                vertical-align: middle;
                            }
                        }

                        .svg-icon {
                            height: 24px;
                            min-height: 24px;
                            min-width: 24px;
                            vertical-align: middle;
                            width: 24px;

                            &.ignored {
                                fill: $maastrichtblue-8;
                            }
                        }
                    }
                }

                .missing-label {
                    align-self: center;
                    color: $battlegrey-7;
                    cursor: pointer;
                    line-height: 1.5em;
                    margin-left: 7px;
                    vertical-align: middle;
                }

                .new-state-flag {
                    align-self: center;
                    color: $maastrichtblue-8;
                    font-style: italic;
                    font-weight: bold;
                }

                .theme-name {
                    color: $battlegrey-6;
                    flex: 0 0 auto;
                    overflow: hidden;
                    max-width: 125px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .title {
                    color: $maastrichtblue-8;
                    line-height: 1.3em;
                    margin-bottom: 10px;
                    vertical-align: middle;
                }

                .significance-icon {
                    height: 20px;
                    position: relative;
                    vertical-align: middle;
                    width: auto;

                    &.resolved {
                        height: 19px;
                    }
                }
            }
        }

        .no-matches {
            height: 100%;
            position: relative;
            text-align: center;

            .no-matches-placeholder {
                top: 50%;
                left: 50%;
                min-width: 225px;
                position: absolute;
                transform: translate(-50%, -50%);
            }
        }
    }

    .issues-footer {
        text-align: center;
        margin-top: 44px;

        .issue-list-scroll-top-icon {
            position: fixed;
            bottom: 20px;
            background-color: $bluechaos-5;
            color: #ffffff;
            border-radius: 50%;
            cursor: pointer;
            width: 34px;
            height: 34px;
        }
    }

    .progress-toolbar {
        color: $maastrichtblue-8;
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        margin-top: 20px;
        padding: 0 20px 0 10px;

        > * {
            align-self: center;
        }

        .issues-progress-bar-wrapper {
            display: flex;
            flex: 1 1 auto;

            .contract-progress-bar-wrapper {
                flex: 1 1 auto;
            }

            .issues-progress-bar-count {
                color: #345991;
                vertical-align: middle;
                padding-left: 5px;
            }

            .issues-progress-bar-label {
                color: $battlegrey-7;
                font-size: 0.8em;
                line-height: 1.4em;
                padding: 0 5px;
                vertical-align: middle;
                align-self: center;
            }
        }
    }
}
