.data-grid-dropdown {
    .data-grid-dropdown-input {
        font-size: 0.875rem;
        display: flex;

        > * {
            align-self: center;
        }

        .menu-item-icon {
            height: 20px;
            margin-right: 5px;
            width: 20px;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
}

.data-grid-dropdown-menu-items {
    .menu-item {
        font-size: 0.875rem;

        .menu-item-icon {
            height: 20px;
            margin-right: 5px;
            width: 20px;
        }
    }
}

.issues-report-data-grid-wrapper {
    .rte-editor {
        border: none;

        .public-DraftEditor-content {
            font-size: 0.875rem;
        }
    }
}
