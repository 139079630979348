@use '@thought-river/ui-components/dist/colors.scss' as *;
@import '../../styles/animations.scss';

$light-button-background-color: white;
$light-button-text-color: $bluechaos-6;
$light-button-hover-color: $bluechaos-1;
$dark-button-background-color: $bluechaos-6;
$dark-button-text-color: white;
$dark-button-hover-color: $bluechaos-8;
$red-button-background-color: $bittenred-6;
$red-button-text-color: white;
$red-button-hover-color: $bittenred-10;
$text-button-text-color: $vintagevelvet-8;
$text-button-hover-color: $vintagevelvet-1;
$success-button-background-color: $thickgreen-9;
$success-button-text-color: white;
$text-success-button-text-color: $thickgreen-9;
$disabled-button-background-color: $battlegrey-4;
$disabled-button-text-color: $battlegrey-6;
$button-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);

.custom-button.MuiButtonBase-root {
    text-transform: none;
    font-size: 14px;
    padding: 4px 8px;
    height: 30px;
    line-height: 22px;
    font-family: 'Lato', 'Segoe UI', Calibri, Arial, sans-serif;
    border-radius: 4px;

    .animated-btn-icon.MuiSvgIcon-root {
        font-size: 12px;
        margin-right: 5px;
    }

    .MuiButton-startIcon {
        margin-right: 5px;
        margin-left: 0;

        .MuiSvgIcon-root {
            font-size: 12px;
        }
    }

    .MuiButton-endIcon {
        margin-left: 5px;
        margin-right: 0;

        .MuiSvgIcon-root {
            font-size: 12px;
        }
    }

    .animated-btn-text {
        white-space: nowrap;
    }

    &.Mui-disabled {
        cursor: not-allowed;
    }

    &.light {
        background: $light-button-background-color;
        color: $light-button-text-color;
        box-shadow: $button-shadow;

        &:hover {
            background: $light-button-hover-color;
            box-shadow: $button-shadow;
        }

        &.Mui-disabled {
            background: $disabled-button-background-color;
            box-shadow: $button-shadow;
            color: $disabled-button-text-color;
        }
    }

    &.dark {
        background: $dark-button-background-color;
        color: $dark-button-text-color;
        box-shadow: $button-shadow;

        &:hover {
            background: $dark-button-hover-color;
            box-shadow: $button-shadow;
        }

        &.Mui-disabled {
            background: $disabled-button-background-color;
            box-shadow: $button-shadow;
            color: $disabled-button-text-color;
        }
    }

    &.text {
        background: none;
        color: $text-button-text-color;
        box-shadow: none;

        &:hover {
            background: $text-button-hover-color;
            box-shadow: none;
        }

        &.Mui-disabled {
            background: none;
            box-shadow: none;
            color: $disabled-button-text-color;
        }
    }

    &.red {
        background: $red-button-background-color;
        color: $red-button-text-color;

        &:hover {
            background: $red-button-hover-color;
            box-shadow: $button-shadow;
        }

        &.Mui-disabled {
            background: $disabled-button-background-color;
            box-shadow: $button-shadow;
        }
    }

    &.animated-button {
        width: fit-content;

        &.animating {
            &.dark {
                animation: background-colour-change-dark-button 0.75s ease-in
                        0.75s 1 forwards,
                    text-colour-change-dark-button 0.75s ease-in 0.75s 1
                        forwards;
            }

            &.light {
                animation: background-colour-change-light-button 0.75s ease-in
                        0.75s 1 forwards,
                    text-colour-change-light-button 0.75s ease-in 0.75s 1
                        forwards;
            }

            &.text {
                animation: text-colour-change-text-button 0.75s ease-in 0.75s 1
                    forwards;
            }

            .animated-btn-icon-before {
                animation: rotate-out 0.75s ease-out 0s 1 forwards;
                transform-origin: center;
            }

            .animated-btn-icon-after {
                animation: zoom-in 0.5s ease-in 0.75s 1 forwards;
                transform-origin: center;
            }

            .animated-btn-text {
                &:before {
                    animation: fade-out 0.3s linear 0.5s 1 forwards,
                        fade-in-animated-btn-text 0.3s ease-in 0.7s 1 forwards;
                }
            }
        }

        &.resetting {
            &.dark {
                animation: background-colour-change-dark-button 0.75s ease-in
                        0.75s 1 reverse,
                    text-colour-change-dark-button 0.75s ease-in 0.75s 1 reverse;
            }

            &.light {
                animation: background-colour-change-light-button 0.75s ease-in
                        0.75s 1 reverse,
                    text-colour-change-light-button 0.75s ease-in 0.75s 1
                        reverse;
            }

            &.text {
                animation: text-colour-change-text-button 0.75s ease-in 0.75s 1
                    reverse;
            }

            .animated-btn-text {
                content: attr(data-text-after);
            }

            .animated-btn-icon-after {
                animation: fade-out 0.5s ease-out 0s 1 forwards;
                transform-origin: center;
            }

            .animated-btn-icon-before {
                animation: fade-in 0.5s ease-in 0s 1 forwards;
                transform-origin: center;
            }

            .animated-btn-text {
                &:before {
                    animation: fade-out 0.3s linear 0 1 forwards,
                        reset-animated-btn-text 0.5s ease-in 0 1 forwards;
                }
            }
        }

        .animated-btn-text {
            &:before {
                content: attr(data-text-before);
            }
        }

        .animated-btn-icon-after {
            opacity: 0;
            position: absolute;
            left: 10px;
            top: 9px;
        }
    }

    @keyframes fade-in-animated-btn-text {
        from {
            content: attr(data-text-before);
            opacity: 0;
        }

        to {
            content: attr(data-text-after);
            opacity: 1;
        }
    }

    @keyframes reset-animated-btn-text {
        from {
            content: attr(data-text-after);
            opacity: 0;
        }

        to {
            content: attr(data-text-before);
            opacity: 1;
        }
    }

    @keyframes background-colour-change-dark-button {
        from {
            background: $dark-button-background-color;
        }

        to {
            background: $success-button-background-color;
        }
    }

    @keyframes text-colour-change-dark-button {
        from {
            color: $dark-button-text-color;
        }

        to {
            color: $success-button-text-color;
        }
    }

    @keyframes background-colour-change-light-button {
        from {
            background: $light-button-background-color;
        }

        to {
            background: $success-button-background-color;
        }
    }

    @keyframes text-colour-change-light-button {
        from {
            color: $light-button-text-color;
        }

        to {
            color: $success-button-text-color;
        }
    }

    @keyframes text-colour-change-text-button {
        from {
            color: $text-button-text-color;
        }

        to {
            color: $text-success-button-text-color;
        }
    }
}
