.content {
    height: 100%;
    overflow-y: auto;
    position: relative;
}

.bannerButtons {
    display: flex;
    gap: 4px;
}

.searchBanner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
}

.searchBannerContainer {
    padding: 6px 16px !important;
}

.searchBannerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.searchInput {
    flex-grow: 1;
}
