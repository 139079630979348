.internal-sharing-modal {
    margin-top: -10px;

    .contract-name {
        font-size: 1.5em;
        margin-bottom: 15px;
    }

    .progress-table {
        border-collapse: collapse;
        width: 100%;
        color: $battlegrey-10;

        th {
            background: $battlegrey-2;
            color: $battlegrey-7;
            padding: 4px;
            text-align: left;

            &.text-center {
                text-align: center;
            }

            &:first-of-type {
                border-bottom-left-radius: 14px;
                border-top-left-radius: 14px;
                padding-left: 10px;
            }

            &:last-of-type {
                border-bottom-right-radius: 14px;
                border-top-right-radius: 14px;
                padding-right: 10px;
            }
        }

        td {
            padding: 8px 4px;

            &.text-center {
                text-align: center;
            }

            &:first-of-type {
                padding-left: 10px;
            }

            &:last-of-type {
                padding-right: 10px;
            }
        }

        .contract-progress-bar {
            align-items: flex-start;
        }
    }

    .section {
        margin-top: 20px;

        .mock-checkbox-wrapper {
            margin-top: 10px;
        }

        .section-title {
            color: $battlegrey-7;
            font-weight: bold;
        }

        .section-subtitle {
            color: $battlegrey-6;
            font-size: 0.82em;
            margin-top: 4px;
            line-height: 1.3em;
        }

        .message {
            border: 1px solid $battlegrey-3;
            box-sizing: border-box;
            height: 180px;
            overflow: auto;
            margin-top: 10px;
            padding: 10px;
            resize: none;
            width: 100%;
        }

        .dropdown-group,
        .multi-dropdown {
            margin-top: 5px;
        }
    }
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
    .internal-sharing-modal {
        min-width: 600px;
    }
}
