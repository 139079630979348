.mobile-menu-burger-button {
    .MuiIconButton-label {
        color: white;
    }
}

.mobile-menu-drawer {
    .MuiDrawer-paper {
        background-color: $header-color;
        color: white;
        min-width: 306px;

        .list-item {
            padding: 15px 15px;
            color: white;

            &.MuiListItem-root {
                &.Mui-disabled {
                    opacity: 1;
                }
            }

            &:hover {
                background-color: $bluechaos-5;
            }

            &.logo-wrapper {
                .logo {
                    max-height: 75px;
                    max-width: auto;
                    height: auto;
                    vertical-align: middle;
                    width: auto;
                }
                &:hover {
                    background: none;
                }
            }

            .text {
                margin: 0;
            }

            .list-item-suffix {
                margin-left: 5px;
                background: $bluechaos-5;
                color: white;
            }
        }

        .icon {
            color: white;
            min-width: initial;
            margin-right: 15px;
        }

        .divider {
            margin: 10px 1em;
            background-color: $battlegrey-3;

            & + .divider {
                display: none;
            }
        }
    }
}
@media only screen and (min-width: $screen-sm) {
    .MuiButtonBase-root {
        &.mobile-menu-burger-button {
            display: none;
        }
    }
}
