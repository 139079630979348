.contract-list {
    background-color: $battlegrey-2;
    display: flex;
    flex: 1;
    flex-direction: column;

    .contract-list-section-top {
        padding: 15px;

        .contract-list-toolbar-wrapper {
            margin: 10px 0;
        }
    }

    .contract-list-section-bottom {
        height: 0;
        display: flex;
        flex-grow: 1;
    }

    .user-info-wrapper {
        text-align: right;
    }
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
}
