@use '@thought-river/ui-components/dist/colors.scss' as *;

.item {
    display: flex;
    align-items: center;
    color: $font-color;
    gap: 8px;
    padding: 4px 8px;
}

.name,
.value {
    display: flex;
    align-items: center;
    gap: 8px;
}

.name {
    flex-shrink: 0;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.value {
    flex-shrink: 1;
    min-width: 0;
}
