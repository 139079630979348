.title {
    display: flex;
    gap: 10px;
    align-items: center;
}

.openContractButton {
    flex-shrink: 0;
}

.openContractIcon {
    font-size: 14px;
    margin-right: 4px;
}

.noClausesMessage {
    padding: 20px 30px;
}
