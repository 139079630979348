@use '@thought-river/ui-components/dist/colors.scss' as *;

.wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $font-color;
    text-decoration: none;
    font-size: 14px;

    &.disabled {
        pointer-events: none;
    }
}
