@use '@thought-river/ui-components/dist/colors.scss' as *;

.wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 600;
        min-height: 38px;
        cursor: pointer;
        color: $font-color;

        .titleText {
            min-width: 120px;
        }

        .expandMoreIcon {
            transition: transform 0.2s;
            font-size: 12px;

            &.expanded {
                transform: rotate(180deg);
            }
        }
    }

    .collapseRoot {
        overflow: auto;
    }
}
