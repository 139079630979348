@use '@thought-river/ui-components/dist/colors.scss' as *;

$user-pic-color-1: #d6af96;
$user-pic-color-2: #af96d6;
$user-pic-color-3: #afd696;
$user-pic-color-4: #96d6af;
$user-pic-color-5: #d696af;
$user-pic-color-6: $bluechaos-5;

.user-icon {
    display: inline-flex;
    position: relative;
    cursor: default;
    text-align: center;
    vertical-align: middle;

    .user-icon-initials {
        align-self: center;
        line-height: 30px;
        height: 30px;
        width: 30px;
        min-width: 30px;
        border-radius: 50%;
        font-size: 0.9rem;
        color: white;

        &.small {
            font-size: 0.8rem;
            height: 25px;
            line-height: 25px;
            min-height: 25px;
            width: 25px;
            min-width: 25px;
        }

        &.large {
            font-size: 2rem;
            height: 76px;
            line-height: 76px;
            width: 76px;
        }

        &.color-1 {
            background-color: $user-pic-color-1;
        }

        &.color-2 {
            background-color: $user-pic-color-2;
        }

        &.color-3 {
            background-color: $user-pic-color-3;
        }

        &.color-4 {
            background-color: $user-pic-color-4;
        }

        &.color-5 {
            background-color: $user-pic-color-5;
        }

        &.color-6 {
            background-color: $user-pic-color-6;
        }

        &.muted {
            background: $battlegrey-3;
            color: $battlegrey-7;
            font-size: 0.7em;
        }
    }

    .user-icon-label {
        color: $battlegrey-10;
        display: inline-block;
        font-size: 0.9rem;
        margin-left: 8px;
        white-space: nowrap;
    }

    .user-icon-label {
        align-self: center;
        vertical-align: middle;
    }
}
