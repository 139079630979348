$toast-background-info: #cfd7e3;
$toast-content-info: #0052cc;
$toast-background-warning: #ffedd1;
$toast-content-warning: #bd5800;
$toast-background-success: #e9f5e9;
$toast-content-success: #247a56;
$toast-background-error: #f8d7ce;
$toast-content-error: #b92c09;

.Toastify {
    .Toastify__toast-container {
        .Toastify__toast {
            .Toastify__toast-body {
                padding: 0;

                .Toastify__toast-icon {
                    .notification-icon {
                        font-size: 1.2rem;
                    }
                }
            }

            .Toastify__close-button {
                position: absolute;
                top: 9px;
                right: 12px;
            }

            .Toastify__progress-bar {
                border-bottom-left-radius: 4px;
            }
        }

        .Toastify__toast--success {
            background: $toast-background-success;
            color: $toast-content-success;

            .Toastify__close-button {
                color: $toast-content-success;
            }

            .Toastify__progress-bar {
                background-color: $toast-content-success;
            }
        }

        .Toastify__toast--warning {
            background: $toast-background-warning;
            color: $toast-content-warning;

            .Toastify__close-button {
                color: $toast-content-warning;
            }

            .Toastify__progress-bar {
                background-color: $toast-content-warning;
            }
        }

        .Toastify__toast--error {
            background: $toast-background-error;
            color: $toast-content-error;

            .Toastify__close-button {
                color: $toast-content-error;
            }

            .Toastify__progress-bar {
                background-color: $toast-content-error;
            }
        }

        .Toastify__toast--info {
            background: $toast-background-info;
            color: $toast-content-info;

            .Toastify__close-button {
                color: $toast-content-info;
            }

            .Toastify__progress-bar {
                background-color: $toast-content-info;
            }
        }
    }

    .form-errors-title {
        padding: 10px 0 0 10px;
    }
}

.MuiCard-root {
    .MuiCardContent-root {
        padding: 20px;

        &:last-child {
            padding-bottom: 20px;
        }
    }
}

.MuiSkeleton-root {
    &.skeleton-row {
        border-radius: 16px;
        height: 24px;
        margin-bottom: 10px;
        transform: scale(1);
        width: 100%;

        &:last-of-type {
            margin-bottom: 0;
        }

        &.medium {
            border-radius: 10px;
            height: 20px;
        }
    }
}

.MuiBackdrop-root {
    opacity: 0 !important;
}
