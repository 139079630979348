@use '@thought-river/ui-components/dist/colors.scss' as *;

.item {
    height: 38px;
    display: flex;
    align-items: center;
    padding: 0;

    &:hover {
        background-color: $bluechaos-1;
    }

    .checkbox {
        height: 38px;
        width: 100%;
        justify-content: center;
    }
}
