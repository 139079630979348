@use '@thought-river/ui-components/dist/colors.scss' as *;

.hybrid-report-table-wrapper {
    .hybrid-report-table {
        border-collapse: collapse;
        display: none;
        font-family: 'Lato', 'Segoe UI', Calibri, Arial, sans-serif;
        height: 0;
        left: -9999px;
        position: absolute;
        width: 0;
        z-index: -1;

        &.copying {
            height: auto;
            width: auto;
            display: table;
        }

        .hybrid-report-table-header,
        .hybrid-report-table-cell {
            background: white;
            border: 1px solid black;
            color: black;
            padding: 0;
            text-align: left;

            &.active {
                .hybrid-report-table-header-sort-label {
                    color: black;
                }

                .MuiTableSortLabel-icon {
                    display: none;
                }
            }

            &.min-width {
                min-width: 95px;
            }
        }

        .linked-clauses-cell {
            .clause-number {
                color: black;
            }
        }

        .hybrid-report-table-row {
            &:last-of-type {
                td {
                    border-bottom: 0;
                }
            }
        }

        .hybrid-report-table-header-sort-label {
            cursor: default;
            user-select: auto;
        }

        .hybrid-report-branding-cell {
            border-left: none;
            border-right: none;

            .filler {
                display: inline-block;
                width: 100%;
            }
        }

        .branding {
            > * {
                display: inline-block;
                font-family: 'Lato', 'Segoe UI', Calibri, Arial, sans-serif;
                line-height: 25px;
                vertical-align: middle;
            }

            img {
                vertical-align: middle;
            }
        }
    }
}
