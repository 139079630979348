@use '@thought-river/ui-components/dist/colors.scss' as *;

.clauses-sidebar-inner-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;

    .sidebar-clause-navigation {
        display: flex;

        .clause-navigation-label {
            flex-grow: 1;
            opacity: 0.9;
        }

        .clause-navigation-controls {
            .clause-navigation-control {
                cursor: pointer;
            }
        }
    }

    .sidebar-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: auto;

        .clauses {
            box-sizing: border-box;
            padding-bottom: 20px;

            .clause-wrapper {
                &.highlighted {
                    .clause-details {
                        opacity: 1;

                        .clause-number {
                            color: $bluechaos-5;
                        }
                    }
                }

                .clause-details {
                    opacity: 0.6;

                    .clause-number-wrapper {
                        display: flex;
                        margin-bottom: 20px;

                        > * {
                            align-self: center;
                        }

                        .clause-number {
                            font-weight: bold;
                        }

                        .view-clause-in-contract-btn-wrapper {
                            line-height: 1.1em;
                            margin-left: 20px;

                            .view-clause-in-contract-btn {
                                border-color: $bluechaos-5;
                                height: auto;
                                min-width: auto;
                                padding: 1px 5.5px;

                                .view-contract-icon {
                                    color: $bluechaos-5;
                                    font-size: 1.1em;
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }

                .clause-partition {
                    border-bottom: 1px solid $battlegrey-2;
                    margin: 40px 0 20px;
                }
            }
        }
    }
}
