@use '@thought-river/ui-components/dist/colors.scss' as *;

.wrapper {
    overflow: auto;
    padding-right: 48px;
    margin-right: -48px;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .errorMsg {
        color: $bittenred-6;
        font-size: 12px;
    }

    .viewAllBtn {
        width: fit-content;
    }

    .list {
        padding: 0;
        .column {
            height: 38px;
            display: flex;
            align-items: center;
            padding: 0 0 0 15px;

            &:hover {
                background-color: $bluechaos-1;
                cursor: pointer;
            }

            &.selected {
                background-color: $bluechaos-1;
                border-left: 3px solid $bluechaos-7;
                padding: 0 0 0 12px;
            }

            .label {
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }
    }
}

.lexibleTree {
    overflow: auto;
    padding: 0 48px 0 0;
    margin-right: -50px;
}
