@use '@thought-river/ui-components/dist/colors.scss' as *;

.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    color: $font-color;
    font-family: 'Lato', sans-serif;

    .header {
        padding: 24px 48px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .title {
            font-weight: 700;
            font-size: 18px;
        }
    }

    .content {
        display: flex;
        padding: 24px 48px 0 48px;
        flex: 1;
        overflow: auto;

        .section {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .leftSection {
            border-right: 1px solid $battlegrey-4;
            flex-basis: 20%;
            min-width: 200px;
        }

        .middleSection {
            border-right: 1px solid $battlegrey-4;
            flex-basis: 45%;
            min-width: 340px;
            padding: 0 48px;
        }

        .rightSection {
            flex-basis: 35%;
            min-width: 320px;
            padding: 0 0 0 48px;
        }

        .sectionTitle {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
        }
    }

    .footer {
        height: 62px;
        background-color: $battlegrey-2;
        padding: 16px 60px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        gap: 16px;
    }
}
