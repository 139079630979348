@use '@thought-river/ui-components/dist/colors.scss' as *;

.summary-pane {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: white;

    .section:first-of-type {
        display: flex;
    }

    .content {
        color: $battlegrey-7;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 0;
        overflow: auto;

        .section {
            border-bottom: 1px solid $battlegrey-3;
            padding: 10px 20px;

            &:last-of-type {
                border-bottom: none;
            }
        }

        .summary-properties-section {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow: hidden;
            padding: 0;
        }

        .summary-properties-header {
            display: flex;
            padding: 5px 25px;
            margin-bottom: 10px;
            align-items: center;
            gap: 5px;

            .template-select-wrapper {
                margin: 0;
                width: calc(100% - 120px);
            }
        }

        .summary-properties-wrapper {
            overflow: auto;
            position: relative;

            .summary-properties-placeholder {
                left: 50%;
                padding: 0 20px;
                position: absolute;
                text-align: center;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
            }
        }

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            overflow-x: hidden;
            overflow-y: scroll;
        }
    }
}
