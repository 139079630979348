.paragraph-widget {
    display: flex;
    position: absolute;
    right: 15px;
    top: 0;
    transform: translate(0, -70%);
    z-index: 1;
    border-radius: 4px;
    box-shadow: $button-shadow;
    background-color: white;

    .link-clause-btn,
    .unlink-clause-btn {
        .link-clause-icon,
        .unlink-clause-icon {
            transform: rotate(-45deg);
        }
    }

    .custom-button {
        box-shadow: none !important;
    }

    .paragraph-widget-seperator {
        display: block;
        width: 1px;
        height: 24px;
        background-color: $battlegrey-3;
        align-self: center;
    }
}
