.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.loader {
    display: inline-block;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.2em solid rgba(200, 200, 200, 0.2);
    border-right: 0.2em solid rgba(200, 200, 200, 0.2);
    border-bottom: 0.2em solid rgba(200, 200, 200, 0.2);
    border-left: 0.2em solid #5ea7ff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: loader 1.1s infinite linear;
    animation: loader 1.1s infinite linear;
}
