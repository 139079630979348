@use '@thought-river/ui-components/dist/colors.scss' as *;

.card {
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 200px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
        0px 2px 4px 0px rgba(0, 0, 0, 0.14);
    border: 1px solid $battlegrey-4;
    cursor: pointer;

    &:hover {
        background-color: $bluechaos-1;

        .actions {
            .overflowMenuBtn {
                visibility: visible;
            }
        }
    }

    &.startFromScratch {
        border: 1px dashed $bluechaos-7;
    }

    .content {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 8px;
        padding: 24px;
        color: $vintagevelvet-6;

        .title {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: $font-color;
        }

        .avatar {
            display: flex;
            gap: 4px;
            margin-top: auto;
            align-items: center;

            .avatarLabel {
                color: $vintagevelvet-6;
            }
        }
    }

    .actions {
        position: relative;

        .overflowMenuBtn {
            position: absolute;
            visibility: hidden;
            top: 26px;
        }

        .arrowIcon {
            font-size: 16px;
            color: $bluechaos-7;
        }
    }
}
