.list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    &.succeeded {
        opacity: 0.6;
    }
}

.stepName {
    display: flex;
    align-items: center;
    gap: 8px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}

.loading {
    margin: auto;
}
