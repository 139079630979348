.bulk-sign-modal {
    color: $battlegrey-10;
    width: 550px;

    .submit-btn {
        &.submitting {
            .btn-label {
                padding-left: 20px;
            }
        }

        .loader-wrapper {
            display: inline-block;
            position: absolute;
            vertical-align: middle;

            .loader {
                border-left-color: $battlegrey-6;
                height: 11px;
                width: 11px;
            }
        }
    }
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
}
