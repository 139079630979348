.search-bar {
    display: inline-block;
    height: 33px;
    position: relative;
    width: 35px;
    vertical-align: middle;

    &.disabled {
        .search-icon {
            cursor: not-allowed;
        }
    }

    .search-icon {
        color: $bluechaos-5;
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
    }

    .search-form {
        min-width: 349px;
        position: absolute;
        top: 108%;
        right: 6px;
        width: 185%;
        z-index: 1001;

        .search-input {
            border: none;
            box-shadow: 0px 1px 3px 0px $battlegrey-5;
            caret-color: $bluechaos-5;
            outline: none;
            padding-left: 10px;
            width: 100%;

            &::placeholder {
                color: $battlegrey-5;
                font-style: italic;
            }
        }

        .search-controls {
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
        }

        .close-search-icon {
            color: $battlegrey-3;
            cursor: pointer;
            vertical-align: middle;
        }

        .matches-wrapper {
            vertical-align: middle;

            .matches {
                color: $battlegrey-7;
                font-size: 0.8em;
                vertical-align: middle;
            }
        }

        .search-nav-wrapper {
            border-left: 1px solid $battlegrey-5;
            display: inline-block;
            padding-left: 10px;
            margin: 0 7px;

            .search-nav-icon-wrapper {
                background-color: $battlegrey-2;
                border-radius: 50%;
                cursor: pointer;
                height: 20px;
                display: inline-block;
                margin-right: 4px;
                position: relative;
                vertical-align: middle;
                width: 20px;

                &:last-of-type {
                    margin-right: 0;
                }

                .search-nav-icon {
                    color: $battlegrey-5;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    vertical-align: middle;
                }
            }
        }
    }

    &.show-matches {
        .search-form {
            .search-input {
                padding-right: 130px;
            }
        }
    }
}

.search-icon-tooltip-popper {
    transform: translate3d(-15px, 112.5px, 0px) !important;
}

.form-control {
    border: 1px solid $battlegrey-5;
    border-radius: 4px;
    padding: 6px 8px;
    box-sizing: border-box;
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
}

@media only screen and (min-width: $screen-md) {
    .search-bar {
        width: 40px;
    }
}
