@use '@thought-river/ui-components/dist/colors.scss' as *;

.detail {
    border-bottom: 1px solid $battlegrey-3;
    margin-top: 20px;
    padding-bottom: 15px;

    &:last-of-type {
        border-bottom: none;
    }

    .detail-info-icon {
        color: $vintagevelvet-8;
        font-size: 1em;
        margin-left: 5px;
    }

    .detail-label {
        display: flex;

        .detail-label-text {
            opacity: 0.7;
            font-size: 0.9em;
        }
    }

    .detail-value {
        margin-top: 5px;
        line-height: 1.4em;
        white-space: pre-wrap;
    }

    .questions {
        .question {
            &:not(:first-of-type) {
                margin-top: 15px;
            }
        }
    }

    .answer {
        background: $property-value-bg;
        color: $property-value-color;
        border-radius: 4px;
        width: fit-content;
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 6px;
        margin-top: 15px;

        .answer-source {
            font-size: 0.9em;
            color: $vintagevelvet-8;
        }
    }
}
