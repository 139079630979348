.party-correction-header {
    color: $maastrichtblue-8;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    background: white;
    box-shadow: 0px 0px 3px 1px $battlegrey-6;
    flex-direction: row;
    max-height: $header-height;
    min-height: $header-height;
    position: relative;
    width: 100%;
    z-index: 2;

    > * {
        align-self: center;
    }

    .contract-name-wrapper {
        font-weight: bold;
        white-space: nowrap;
        padding-left: 10px;

        > * {
            vertical-align: middle;
        }

        .contract-name-label {
            display: none;
        }

        .contract-name {
            display: inline-block;
            overflow: hidden;
            max-width: 150px;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: 15px;
        }
    }

    .submit-wrapper {
        white-space: nowrap;
        margin-left: auto;
        padding-right: 25px;

        .submit-label {
            color: $battlegrey-7;
            display: none;
            vertical-align: middle;
        }

        .submit-btn {
            margin-left: 15px;
        }
    }

    .loader-wrapper {
        color: $bluechaos-5;
        height: 34px;
        line-height: 34px;

        > * {
            vertical-align: middle;
        }

        .loader {
            border-left-color: $bluechaos-5;
            height: 15px;
            vertical-align: middle;
            width: 15px;
        }

        .loader-text {
            margin-left: 5px;
        }
    }
}

@media only screen and (min-width: $screen-sm) {
    .party-correction-header {
        .contract-name-wrapper {
            .contract-name {
                max-width: 200px;
            }

            .contract-name-label {
                display: inline-block;
            }
        }

        .submit-wrapper {
            .submit-label {
                display: inline-block;
            }
        }
    }
}

@media only screen and (min-width: $screen-md) {
    .party-correction-header {
        .contract-name-wrapper {
            .contract-name {
                max-width: 300px;
            }
        }
    }
}

@media only screen and (min-width: $screen-lg) {
    .party-correction-header {
        .contract-name-wrapper {
            .contract-name {
                max-width: 500px;
            }
        }
    }
}
