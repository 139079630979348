$header-height: 50px;

$risk-high: #f0541d;
$risk-low: #ffb66f;
$risk-medium: #ff931e;
$risk-missing: #a33b1a;
$risk-okay: #569901;
$risk-okay-alt: #e0f0d9;
$risk-showstopper: #a33b1a;
$risk-unscored: #b3b3b3;

$screen-xl: 1400px;
$screen-lg: 1200px;
$screen-md: 930px;
$screen-sm: 768px;
$screen-xs: 600px;

$time-normal: 250ms;
$radius-small: 4px;
$radius-medium: 6px;
$sidebar-min-width: 525px;

$dashed-border-mid-point: 67%;
$dashed-border-width: 12px;
$dashed-border-height: 1px;
$dashed-border-height-highlighted: 2px;

@mixin dashed-border {
    background-image: repeating-linear-gradient(
            to right,
            $battlegrey-5 0%,
            $battlegrey-5 $dashed-border-mid-point,
            transparent $dashed-border-mid-point,
            transparent 100%
        ),
        repeating-linear-gradient(
            to right,
            $battlegrey-5 0%,
            $battlegrey-5 $dashed-border-mid-point,
            transparent $dashed-border-mid-point,
            transparent 100%
        ),
        repeating-linear-gradient(
            to bottom,
            $battlegrey-5 0%,
            $battlegrey-5 $dashed-border-mid-point,
            transparent $dashed-border-mid-point,
            transparent 100%
        ),
        repeating-linear-gradient(
            to bottom,
            $battlegrey-5 0%,
            $battlegrey-5 $dashed-border-mid-point,
            transparent $dashed-border-mid-point,
            transparent 100%
        );

    background-position: left top, left bottom, left top, right top;

    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;

    background-size: $dashed-border-width $dashed-border-height,
        $dashed-border-width $dashed-border-height,
        $dashed-border-height $dashed-border-width,
        $dashed-border-height $dashed-border-width;
}

@mixin dashed-border-highlighted {
    background-size: $dashed-border-width $dashed-border-height-highlighted,
        $dashed-border-width $dashed-border-height-highlighted,
        $dashed-border-height-highlighted $dashed-border-width,
        $dashed-border-height-highlighted $dashed-border-width;
}

@mixin dashed-border-error {
    background-image: repeating-linear-gradient(
            to right,
            $bittenred-8 0%,
            $battlegrey-7 $dashed-border-mid-point,
            transparent $dashed-border-mid-point,
            transparent 100%
        ),
        repeating-linear-gradient(
            to right,
            $bittenred-8 0%,
            $bittenred-8 $dashed-border-mid-point,
            transparent $dashed-border-mid-point,
            transparent 100%
        ),
        repeating-linear-gradient(
            to bottom,
            $bittenred-8 0%,
            $bittenred-8 $dashed-border-mid-point,
            transparent $dashed-border-mid-point,
            transparent 100%
        ),
        repeating-linear-gradient(
            to bottom,
            $bittenred-8 0%,
            $bittenred-8 $dashed-border-mid-point,
            transparent $dashed-border-mid-point,
            transparent 100%
        );
}
