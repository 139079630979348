@use '@thought-river/ui-components/dist/colors.scss' as *;

.addedUsersList {
    list-style: none;
    overflow-y: auto;
    padding: 0;
    margin: 0;
}

.footer {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.paper {
    height: 500px;
}
