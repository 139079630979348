.app-container {
    height: 100%;
    position: relative;

    .app {
        display: flex;
        height: 100%;
        flex-direction: column;
        text-align: left;
    }

    .main {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        min-height: 0;
        overflow: auto;
    }
}

.MuiButton-containedSizeSmall,
.MuiButton-outlinedSizeSmall {
    .MuiSvgIcon-root {
        font-size: 1rem;
        margin-right: 8px;
    }
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
}
