.arrowButton {
    transition: transform 0.2s;

    &.expanded {
        transform: rotate(90deg);
    }

    .icon {
        font-size: 10px !important;
    }
}
