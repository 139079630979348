@use '@thought-river/ui-components/dist/colors.scss' as *;

.htmlContent {
    max-height: 250px;
    overflow-y: auto;
    flex-grow: 1;
}

.clausesWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .highlightedClause {
        background: $bluechaos-7 !important;
        color: #ffffff !important;
    }
}

.clauseTooltip:global(.MuiTooltip-tooltip) {
    max-width: 800px;
    padding: 0;
}

.clauseTooltipContent {
    max-height: 120px;
    overflow-y: auto;
    padding: 6px 12px;
}
