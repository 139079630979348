@use '@thought-river/ui-components/dist/colors.scss' as *;

.progress-steps {
    margin-bottom: 16px;

    .MuiStepConnector-root {
        left: -50%;
        right: 50%;
        top: 11px;

        .MuiStepConnector-line {
            border-color: $battlegrey-5;
        }

        &.MuiStepConnector-active,
        &.MuiStepConnector-completed {
            .MuiStepConnector-line {
                border-color: $bluechaos-5;
            }
        }
    }

    .MuiStepLabel-label {
        display: none;
    }

    .step-icon-circle {
        background: white;
        border-radius: 50%;
        border: 2px solid $battlegrey-5;
        height: 12px;
        width: 12px;
        z-index: 1;

        &.active {
            border-color: $bluechaos-5;
        }
    }

    .step-icon-completed {
        background: $bluechaos-5;
        border-radius: 50%;
        color: white;
        font-size: 12px;
        padding: 2px;
        z-index: 1;
    }
}
