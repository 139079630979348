@use '@thought-river/ui-components/dist/colors.scss' as *;

.header-tabs {
    display: flex;
    height: 100%;
    min-height: 50px;

    &.light {
        .MuiTabs-root {
            .MuiTab-root {
                color: $vintagevelvet-8;

                &.Mui-selected {
                    color: $bluechaos-5;
                }
            }
        }
    }

    .MuiTabs-root {
        align-self: center;
        color: white;
        min-height: 50px;
        height: 100%;
        padding: 0;

        .MuiTab-root {
            color: white;
            min-width: auto;
            text-decoration: none;
            padding: 6px 30px;
            height: 100%;
            font-weight: bold;
            position: relative;
            text-transform: capitalize;
            width: auto;

            &:after {
                background: transparent;
                bottom: 0;
                content: '';
                height: 3px;
                position: absolute;
                transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                width: 100%;
            }

            &.Mui-selected {
                &:after {
                    background: $bluechaos-5;
                }
            }

            &:hover {
                &:after {
                    background: $bluechaos-5;
                }
            }
        }

        .MuiTabs-flexContainer {
            height: 100%;
        }

        .Mui-selected {
            color: $bluechaos-5;
        }

        .MuiTab-textColorInherit {
            opacity: 1;
        }

        .MuiTabs-indicator {
            background: transparent;
        }
    }
}
