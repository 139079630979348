@use '@thought-river/ui-components/dist/colors.scss' as *;

.rtf-logo {
    color: $maastrichtblue-8;
    position: relative;
    width: 20px;
    height: 20px;

    .file-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 1.7rem;
        transform: translate(-50%, -50%);
    }

    .file-type-label {
        background: white;
        border: 1px solid $maastrichtblue-8;
        display: inline-block;
        font-size: 0.5em;
        padding: 0 3px;
        border-radius: 1px;
        position: absolute;
        left: 50%;
        top: 65%;
        transform: translate(-50%, -50%);
    }
}
