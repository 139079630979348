@use '@thought-river/ui-components/dist/colors.scss' as *;

.container {
    height: 100%;
    display: grid;
    place-items: center;
    color: $font-color;
}

.error {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
}
