@use '@thought-river/ui-components/dist/colors.scss' as *;

.steps {
    width: 100%;
    display: flex;
}

.status {
    width: 100%;
    display: flex;
    gap: 8px;
}

.icon {
    font-size: 16px;
}

.statusIcons {
    margin-left: auto;
    display: flex;
}

.signed {
    color: $purplecabbage-4;
}

.template {
    color: $purplecabbage-3;
}

.readyIcon {
    font-size: 16px;
    color: $thickgreen-5;
}

.iconWithLabel {
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 12px;
    min-width: 0;
}

.partiesAlert {
    color: $orangedanger-5;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
