.parties-group {
    background: white;
    padding: 10px 25px 10px;
    position: relative;

    &:first-of-type {
        padding-top: 0;
    }

    &.collapsible {
        .collapse-toggle {
            cursor: pointer;
            display: initial;
        }

        .parties-overlay {
            background: $orangedanger-5;
        }
    }

    &.expanded {
        .parties-content {
            display: block;
        }
    }

    &.hide-on-mobile {
        display: none;
    }

    .collapse-toggle {
        color: $orangedanger-5;
        cursor: pointer;
        position: absolute;
        right: 25px;
        top: 9px;

        &.collapse {
            top: 11px;

            .toggle-icon {
                font-size: 1.3em;
            }
        }
    }

    .flip-all-btn {
        color: $bluechaos-5;
        cursor: pointer;
        text-align: right;

        .flip-all-icon {
            vertical-align: middle;
        }
    }

    .parties-content {
        display: none;
        position: relative;
    }

    .parties-list {
        background: white;
        border-radius: 5px;
        border: 1px solid $battlegrey-5;
        height: 120px;
        list-style-type: none;
        margin: 2px auto 5px;
        overflow-y: auto;
        padding: 8px 10px;
        text-align: left;
    }

    .parties-overlay {
        background: $bluechaos-5;
        border-radius: 4px;
        height: 100%;
        opacity: 0.92;
        position: absolute;
        top: 0;
        width: 100%;
        cursor: pointer;
        animation: fadeIn;
        animation-duration: 0.2s;

        .overlay-content {
            color: white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            .overlay-add-icon {
                display: block;
                font-size: 4em;
                margin: 0 auto;
            }

            .overlay-text {
                font-weight: bold;
            }
        }
    }

    .parties-subtitle {
        color: $battlegrey-7;
        font-size: 0.9em;
        margin: 4px auto 3px;

        .building-icon {
            vertical-align: text-top;
            font-size: 1em;
        }
    }

    .parties-title {
        color: $battlegrey-7;
        font-size: 1.1em;
        font-weight: bold;
    }
}

@media only screen and (min-width: $screen-sm) {
    .parties-group {
        padding: 12px 25px 12px;

        &.collapsible {
            bottom: 0;
            opacity: 0.5;
            width: calc(100% - 50px);
            margin-top: 3px;
            background-color: $battlegrey-3;

            &.expanded {
                background-color: $orangedanger-1;
                box-shadow: 3px 1px 4px $risk-unscored;
                position: absolute;
                opacity: 1;
                z-index: 1;

                .parties-content {
                    display: block;
                }

                &:hover {
                    background-color: $orangedanger-1;
                }
            }

            &:hover {
                opacity: 1;
                background-color: $orangedanger-1;
            }

            .parties-content {
                display: none;
            }
        }

        &.compact {
            .parties-list {
                height: 107px;
            }
        }

        &.counter-parties {
            .flip-all-btn {
                display: none;
            }
        }

        &.hide-on-mobile {
            display: block;
        }

        .collapse-toggle {
            display: none;
        }

        .flip-all-btn {
            bottom: -27px;
            position: absolute;
            top: auto;
            right: 0;
            z-index: 1;
        }

        .parties-content {
            display: block;
        }
    }
}

.pseudo-link {
    color: $bluechaos-5;
    cursor: pointer;

    &.dark {
        color: black;
    }

    &:hover {
        text-decoration: underline;
    }
}
