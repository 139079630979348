@use '@thought-river/ui-components/dist/colors.scss' as *;

.help-menu {
    color: $vintagevelvet-8;

    .MuiPaper-root {
        top: 48px !important; // overrides the default menu position
    }

    .menu-item {
        padding: 0;

        &:hover {
            background: $bluechaos-1;
        }

        a {
            color: $vintagevelvet-8;
            display: flex;
            padding: 4px 16px;
            width: 100%;

            &:hover {
                text-decoration: none;
            }
        }

        .menu-item-icon {
            color: $vintagevelvet-8;
            min-width: auto;

            &.secondary {
                margin-left: 8px;
            }

            svg {
                align-self: center;
                font-size: 1.1em;
            }
        }

        .menu-item-label {
            margin-left: 8px;

            span {
                font-size: 0.9rem;
            }
        }
    }
}
