@use '@thought-river/ui-components/dist/colors.scss' as *;

.contract-progress-bar-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;

    &.full-width {
        .contract-progress-bar {
            width: 100%;

            .contract-progress-bar-background {
                width: 100%;
            }
        }
    }

    .labels {
        display: flex;
        justify-content: space-between;
        margin-bottom: 7.5px;
        width: 100%;
    }

    .contract-progress-bar {
        display: flex;
        align-items: center;

        .contract-progress-bar-background {
            align-self: center;
            border-radius: 8px;
            box-sizing: border-box;
            height: 16px;
            overflow: hidden;
            width: 157px;

            .contract-progress-bar-filler {
                border-radius: 8px;
                height: 16px;
            }
        }

        .contract-progress-bar-count {
            &.left {
                margin-right: 10px;
            }

            &.right {
                margin-left: 10px;
            }
        }
    }
}
