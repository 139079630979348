.party-contract {
    background: $battlegrey-2;
    display: flex;
    flex: 1 0;
    flex-direction: column;
    min-width: 100%;
    order: 2;

    .party-contract-content {
        padding: 20px;
        display: flex;
        flex-direction: column;
        flex: 1 0;
        margin: 0 auto;
        max-width: 1200px;

        .contract-body {
            background: white;
            border-radius: 4px;
            border: 1px solid $battlegrey-3;
            height: 0;
            flex: 1 1 auto;
            overflow-y: auto;
            padding: 30px;
            text-align: left;

            .paragraphs {
                color: $battlegrey-7;
                list-style-type: none;
                margin: 0;
                padding: 0;

                .paragraph {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .party-contract-header {
        align-items: center;
        font-size: 1em;
        padding-left: 1px;
        padding-bottom: 20px;
        color: $font-color;

        .building-icon {
            vertical-align: bottom;
        }

        .logo-img {
            color: $battlegrey-7;
            height: auto;
            max-height: 45px;
            max-width: 150px;
            width: auto;
        }

        .page-title {
            color: $battlegrey-7;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 165px;
        }

        .header-title {
            color: $battlegrey-7;
            font-weight: bold;
        }
    }
}

@media only screen and (min-width: $screen-sm) {
    .party-contract {
        flex: 1 0 0;
        min-width: 850px;
        order: 1;
        width: 0;

        .contract-body {
            height: 0;
        }
    }
}
