@use '@thought-river/ui-components/dist/colors.scss' as *;

.profile-menu {
    color: $font-color;

    .MuiList-padding {
        padding-bottom: 0;
        padding-top: 0;
    }

    .MuiPaper-root {
        top: 48px !important; // overrides the default menu position
    }

    .menu-item {
        &.profile-info {
            background: white;
            cursor: default;
            border-bottom: 1px solid $battlegrey-3;
            padding: 8px 16px 4px 16px;

            &:hover {
                background: white;
            }
        }

        &:hover {
            background: $bluechaos-1;
        }

        a {
            color: $font-color;
            display: flex;

            &:hover {
                text-decoration: none;
            }
        }

        .menu-item-icon {
            color: $vintagevelvet-8;
            min-width: auto;

            &.secondary {
                margin-left: 8px;
            }

            svg {
                align-self: center;
                font-size: 1.1em;
            }
        }

        .menu-item-label {
            margin-left: 8px;

            span {
                font-size: 0.9rem;
            }
        }

        .user-info-wrapper {
            display: flex;

            .user-icon {
                font-weight: normal;

                .user-icon-initials {
                    &.large {
                        display: flex;
                        flex-direction: column;
                        font-size: 18px;
                        height: 55px;
                        justify-content: center;
                        width: 55px;
                    }
                }
            }

            .user-info-details {
                color: $font-color;
                font-weight: normal;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 16px;
                gap: 4px;

                .user-name {
                    color: $font-color;
                    font-size: 14px;
                }

                .user-email,
                .user-account {
                    font-size: 12px;
                }
            }
        }
    }
}
