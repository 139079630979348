.summary-properties {
    height: 100%;
    overflow: auto;
    padding-left: 5px;

    .properties-placeholder {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
