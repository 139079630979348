.auth {
    height: 100%;
    position: relative;

    .auth0-logout-iframe {
        display: none;
        position: absolute;
        height: 0;
        width: 0;
    }
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
}
