@use '@thought-river/ui-components/dist/colors.scss' as *;

.table {
    th,
    td {
        padding: 4px;
        color: $font-color;
        font-family: 'Lato', sans-serif;
        border-bottom: 0;
    }
}
