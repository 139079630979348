@use '@thought-river/ui-components/dist/colors.scss' as *;

.badge {
    box-sizing: border-box;
    padding: 2px 6px;
    line-height: 20px;
    font-size: 12px;
    border-radius: 3px;
    color: $vintagevelvet-8;
    width: fit-content;

    &.color-0 {
        background-color: $vintagevelvet-2;
    }
    &.color-1 {
        background-color: $bittenred-2;
    }
    &.color-2 {
        background-color: $orangedanger-2;
    }
    &.color-3 {
        background-color: $citrussplash-2;
    }
    &.color-4 {
        background-color: $thickgreen-2;
    }
    &.color-5 {
        background-color: $deepaqua-2;
    }
    &.color-6 {
        background-color: $bluechaos-2;
    }
    &.color-7 {
        background-color: $feistypink-2;
    }
    &.color-8 {
        background-color: $purplecabbage-2;
    }
    &.color-9 {
        background-color: $maastrichtblue-2;
    }
}

.multiDealTypes {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $vintagevelvet-6;
    width: fit-content;
}

.badges {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}
