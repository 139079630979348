@use '@thought-river/ui-components/dist/colors.scss' as *;

.rte-container {
    min-height: 0;
    flex-grow: 1;

    // Fixes a bug where block elements with top/bottom margin would show a scrollbar
    .rdw-editor-main {
        overflow-x: hidden;
        overflow-y: auto;
    }

    .rte-editor {
        border-radius: 4px;
        box-sizing: border-box;
        color: $font-color;
        height: 100%;
        overflow: hidden;
        padding: 0 0 10px;
        position: relative;
        display: flex;
        flex-direction: column;

        .public-DraftStyleDefault-block {
            margin: 0;
        }

        .public-DraftEditorPlaceholder-root,
        .public-DraftEditor-content {
            box-sizing: border-box;
            padding: 0 10px;
        }

        &.editing {
            border: 1px solid $input-border-color;

            .rte-toolbar {
                &.show-on-focus {
                    max-height: 100%;
                    opacity: 1;
                    animation-name: slide-in-down;
                }
            }
        }

        .rte-toolbar {
            border: none;
            margin: 6px 1px;

            &.show-on-focus {
                max-height: 0;
                opacity: 0;
                transition: 0.3s ease-in;
            }

            .bold,
            .italic,
            .underline,
            .strikethrough,
            .clear-formatting,
            .bullet-list,
            .numbered-list {
                display: none;
            }

            &.show-bold {
                .bold {
                    display: block;
                }

                .dropdown-bold {
                    display: none;
                }
            }

            &.show-italic {
                .italic {
                    display: block;
                }

                .dropdown-italic {
                    display: none;
                }
            }

            &.show-bullet-list {
                .bullet-list {
                    display: block;
                }

                .dropdown-bullet-list {
                    display: none;
                }
            }

            &.show-numbered-list {
                .numbered-list {
                    display: block;
                }

                .dropdown-numbered-list {
                    display: none;
                }
            }

            &.show-underline {
                .underline {
                    display: block;
                }

                .dropdown-underline {
                    display: none;
                }
            }

            &.show-strikethrough {
                .strikethrough {
                    display: block;
                }

                .dropdown-strikethrough {
                    display: none;
                }
            }

            &.show-clear-formatting {
                .clear-formatting {
                    display: block;
                }

                .dropdown-clear-formatting {
                    display: none;
                }
            }

            &.hide-formatting-dropdown {
                .formatting-dropdown {
                    display: none;
                }
            }

            &.hide-list-dropdown {
                .list-dropdown {
                    display: none;
                }
            }

            .custom-toolbar-mock-dropdown {
                display: flex;
            }

            .rdw-option-wrapper,
            .block-type-options {
                height: 0;
                opacity: 0;
                position: absolute;
                visibility: hidden;
                width: 0;
            }

            .divider {
                background: $battlegrey-3;
                display: inline-block;
                height: 19px;
                margin: 0px 2px -2px;
                width: 1px;
            }

            .toolbar-menu {
                background: white;
                border-radius: 4px;
                padding: 8px 0;

                .custom-toolbar-option {
                    margin: 0;

                    .toolbar-label-wrapper {
                        border-radius: 0;
                        box-sizing: border-box;
                        display: flex;
                        padding: 4px 12px;
                        width: 100%;

                        &:hover {
                            background: rgba(9, 30, 66, 0.08);
                            transition-duration: 0s, 0.15s;

                            .toolbar-label {
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }

        // Overrides the position of the 'open in new tab' icon that appears over links
        .rdw-link-decorator-wrapper {
            position: relative;

            .rdw-link-decorator-icon {
                height: 12px;
                left: 95%;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                width: 12px;
            }
        }
    }
}
