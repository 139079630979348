.data-grid-dropdown {
    .data-grid-dropdown-input {
        display: flex;
        font-size: 0.875rem;
        padding-bottom: 6px;
        padding-top: 6px;

        > * {
            align-self: center;
        }

        .MuiSvgIcon-root {
            margin-right: 5px;
        }
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }
}

.data-grid-dropdown-menu-items {
    .menu-item {
        font-size: 0.875rem;

        .MuiSvgIcon-root {
            margin-right: 5px;
        }
    }
}
