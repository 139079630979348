.header {
    background: $header-color;
    border-bottom: 1px solid $battlegrey-3;
    box-sizing: border-box;
    display: flex;
    min-height: 52px;
    padding: 0px 10px 0 10px;
    width: 100%;
    height: 52px;

    > * {
        align-self: center;
    }

    .tab-label-wrapper {
        position: relative;

        .beta-badge {
            background: $orangedanger-5;
            color: white;
            height: 19px;
            font-size: 0.625rem;
            font-weight: normal;
            margin-left: 4px;
            text-transform: uppercase;

            &:hover {
                background-color: $orangedanger-5;
                cursor: default;
            }
        }
    }

    .contract-tabs {
        display: flex;
        flex: 1 1 auto;
        max-width: calc(100% - 385px);
    }

    .logo-wrapper {
        text-align: left;

        .logo {
            height: auto;
            max-height: 25px;
            max-width: 150px;
            vertical-align: middle;
            width: auto;
        }

        .logo-mini {
            display: none;
        }
    }

    .header-tabs {
        margin-left: 50px;
    }

    .nav-options {
        display: flex;
        margin-left: auto;
        height: 100%;
    }

    .header-menu-anchor {
        align-items: center;
        background-color: $header-color;
        border-bottom: 3px solid $header-color;
        box-sizing: border-box;
        color: white;
        display: flex;
        justify-content: center;
        cursor: pointer;
        padding: 0 10px;

        .user-icon {
            cursor: pointer;
        }

        &:hover {
            border-bottom: 3px solid $bluechaos-5;
        }

        &.open {
            border-bottom: 3px solid $bluechaos-5;
        }

        .menu-icon {
            cursor: pointer;
            height: 22px;
            vertical-align: middle;
            width: 22px;
        }
    }
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (max-width: $screen-sm) {
    .header {
        padding-left: 0;
        .logo-wrapper {
            .logo {
                display: none;
                height: auto;
                max-height: 25px;
                max-width: 150px;
                vertical-align: middle;
                width: auto;
            }

            .logo-mini {
                display: block;
                height: 25px;
                width: 25px;
            }
        }
    }
}
