@use '@thought-river/ui-components/dist/colors.scss' as *;

.contract-upload-wizard-modal-wrapper {
    color: $vintagevelvet-8;
    min-width: 600px;
    padding: 0;

    .modal-dialog-window-header {
        justify-content: center;
        margin-bottom: 0;
        padding: 16px 24px;

        .modal-dialog-title {
            color: $font-color;
            font-size: 1.125rem;
        }
    }
}

.contract-upload-wizard-modal-content {
    display: flex;
    padding: 6px 24px 45px 24px;
    flex-direction: column;

    .contract-upload-wizard-modal-step-description {
        margin-bottom: 30px;
        text-align: center;

        .building-icon {
            color: $bluechaos-5;
            font-size: 1.3em;
            vertical-align: middle;
        }
    }

    .parties-editor-wrapper {
        .draggable-label {
            &:hover {
                .party-item-wrapper {
                    .party-item-icon {
                        &.discard-icon {
                            opacity: 1;
                        }
                    }
                }
            }

            .party-item-wrapper {
                display: flex;

                > * {
                    align-self: center;
                }

                .discard-party-tooltip {
                    margin-left: auto;
                }

                .party-item-icon {
                    color: $battlegrey-5;
                    font-size: 1em;
                    margin-left: 6px;
                    vertical-align: text-top;

                    &.active {
                        color: $bluechaos-5;
                    }

                    &.discard-icon {
                        color: $bittenred-9;
                        margin-left: auto;
                        opacity: 0;
                        transition: opacity 0.2s;
                    }
                }

                .party-item-label {
                    display: inline-block;
                    overflow: hidden;
                    max-width: 155px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    .streams-dropdown {
        align-self: center;
        margin: 0 auto 75px;
        width: 388px;

        label {
            width: 80px;
        }
    }

    .parties-editor-wrapper {
        .dual-list-editor {
            .lists-wrapper {
                padding: 0;

                .list {
                    min-height: 252px;
                }

                .list-title {
                    font-weight: bold;
                }
            }
        }
    }
}

.contract-upload-edit-parties {
    display: flex;
    margin-top: 25px;
    gap: 10px;
    margin-left: 22px;
}

.file-list {
    margin: 30px auto 0;
    max-width: 300px;
    max-height: 92px;
    overflow-y: auto;
    padding: 0 20px;

    .file-upload-progress {
        margin-top: 10px;

        &:first-of-type {
            margin-top: 0;
        }
    }
}

.contract-upload-wizard-modal-footer {
    display: flex;
    gap: 8px;
}
