@use '@thought-river/ui-components/dist/colors.scss' as *;

.wrapper {
    overflow: auto;
    padding: 0 48px 0 0;
    margin-right: -48px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .lists {
        overflow: auto;
        padding: 0 48px 0 0;
        margin-right: -48px;
        height: 100%;
    }
}
