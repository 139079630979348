@use '@thought-river/ui-components/dist/colors.scss' as *;

.added-user {
    display: flex;
    padding: 0.5em 0;
    margin: 0.5em 0;
    gap: 8px;

    &:hover {
        .delete-added-user-btn {
            visibility: visible;
        }
    }

    .role-select {
        width: 100px;
    }
}

.added-user-icon {
    flex-shrink: 0;
}

.new-added-user {
    background-color: $citrussplash-1;
    animation: new-user-background-colour-change 3s ease-in 0.5s 1 forwards;
}

.added-user-details {
    flex-grow: 1;
    font-size: 0.85em;
}

.added-user-name {
    color: $vintagevelvet-8;
}

.added-user-email {
    margin-top: 5px;
    color: $battlegrey-6;
}

.delete-added-user-btn {
    visibility: hidden;
    color: $bittenred-8;

    &:hover {
        background-color: transparent;
        color: $bittenred-9;
    }
}

.user-updated-badge {
    color: $feistypink-8;
    background-color: $feistypink-1;
    border-radius: 64px;
    font-size: 10px;
    align-self: center;
    padding: 2px 6px;
    margin-right: 1em;
}

@keyframes new-user-background-colour-change {
    from {
        background: $citrussplash-1;
    }

    to {
        background: white;
    }
}
