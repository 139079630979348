@use '@thought-river/ui-components/dist/colors.scss' as *;
@import '../../styles/variables.scss';

.sec-nav {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border: 1px solid $vintagevelvet-1;
    padding: 0 16px;
    min-height: 62px;
    gap: 16px;
}

.sec-nav-left-col {
    width: 50%;
    min-width: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    gap: 16px;
    height: 100%;

    @media only screen and (max-width: $screen-md) {
        & {
            flex-grow: 1;
            width: auto;
        }
    }
}

.sec-nav-right-col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin-left: auto;
}

.sec-nav-contract-details-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    min-width: 0;
}

.sec-nav-contract-details {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-top: auto;
    justify-content: flex-start;
}

.sec-nav-contract-name {
    min-width: 250px;
}

.sec-nav-save-status {
    @media only screen and (max-width: $screen-md) {
        & {
            display: none;
        }
    }
}

.sec-nav-tabs {
    display: flex;
    gap: 24px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-bottom: -1px;
    margin-top: auto;
}

a.sec-nav-tab-link {
    text-decoration: none;
    color: $vintagevelvet-8;
    padding-bottom: 6px;
    display: block;

    &:hover {
        text-decoration: none;
        color: $bluechaos-7;
    }

    &.active {
        color: $bluechaos-7;
        border-bottom: 1px solid $bluechaos-7;
    }
}

.sec-nav-controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    @media only screen and (max-width: $screen-md) {
        & {
            display: none;
        }
    }
}

.sec-nav-user-icons {
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
}
