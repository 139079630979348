@use '@thought-river/ui-components/dist/colors.scss' as *;

.voting {
    display: flex;
    gap: 4px;

    .voted {
        &:not(.active) {
            display: none;
        }
    }

    .vote-up {
        background: $thickgreen-5;

        &:hover {
            background: $thickgreen-8;
        }
    }

    .vote-down {
        background: $bittenred-5;

        &:hover {
            background: $bittenred-8;
        }
    }

    .vote-icon.MuiSvgIcon-root {
        font-size: 10px;
        color: white;
    }
}
