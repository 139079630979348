.viewReportPage {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px 48px;
    overflow: hidden;
    gap: 24px;

    .cell {
        overflow: hidden;
    }

    .compactDensity {
        .cell {
            padding: 8px 10px;
        }
    }

    .standardDensity {
        .cell {
            padding: 15px 10px;
        }
    }

    .comfortableDensity {
        .cell {
            padding: 22px 10px;
        }
    }
}
