.square-button {
    height: 50px;
    width: 50px;

    .square-button-link {
        display: inline-block;
        height: 100%;
        width: 100%;
        position: relative;
        cursor: pointer;
        color: $maastrichtblue-5;

        &.dark {
            background: $maastrichtblue-5;
            color: white;
        }

        .square-button-icon {
            position: absolute;
            font-size: 1.5em;
            vertical-align: middle;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
}
