@use '@thought-river/ui-components/dist/colors.scss' as *;

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;

    .collapseList {
        gap: 12px;

        .listTitle {
            font-size: 14px;
            padding: 0 8px;
            background-color: $battlegrey-2;
            border-radius: 4px;
        }
    }
}
