.paragraph-wrapper {
    margin: 0 30px;
    background: white;
    color: $font-color;
    line-height: 2.3em;
    position: relative;

    &.half-opacity {
        .marker {
            opacity: 0.4;
        }
    }

    &:hover {
        .property-link-widget {
            visibility: visible;
        }
    }

    &.highlighted {
        background: $none-secondary-color;

        &.high {
            background: $high-secondary-color;
        }

        &.low {
            background: $low-secondary-color;
        }

        &.medium {
            background: $medium-secondary-color;
        }

        &.ok {
            background: $ok-secondary-color;
        }

        &.showstopper {
            background: $showstopper-secondary-color;
        }

        &.unscored {
            background: $none-secondary-color;
        }

        &.unfamiliar {
            background: $unfamiliar-secondary-color;
        }

        &.uncommon {
            background: $uncommon-secondary-color;
        }

        &.common {
            background: $common-secondary-color;
        }

        &.template {
            background: $template-secondary-color;
        }
    }

    &.high {
        .marker {
            background: $high-primary-color;
        }
    }

    &.low {
        .marker {
            background: $low-primary-color;
        }
    }

    &.medium {
        .marker {
            background: $medium-primary-color;
        }
    }

    &.ok {
        .marker {
            background: $ok-primary-color;
        }
    }

    &.showstopper {
        .marker {
            background: $showstopper-primary-color;
        }
    }

    &.unfamiliar {
        .marker {
            background: $unfamiliar-primary-color;
        }
    }

    &.uncommon {
        .marker {
            background: $uncommon-primary-color;
        }
    }

    &.common {
        .marker {
            background: $common-primary-color;
        }
    }

    &.template {
        .marker {
            background: $template-primary-color;
        }
    }

    .marker {
        position: absolute;
        top: 50%;
        height: 97%;
        width: 4px;
        transform: translateY(-50%);
        z-index: 5;
        border-radius: 15px;
    }

    .paragraph-content {
        word-break: break-word;
        padding: 7.5px 30px;

        .search-result {
            background-color: #ffeb3b;
            color: $battlegrey-7;
        }

        .bold {
            font-weight: bold;
        }

        .highlighted-text {
            background-color: $bluechaos-1;
        }

        .padding-right-5px {
            padding-right: 5px;
        }

        .padding-right-10px {
            padding-right: 10px;
        }

        .padding-left-5px {
            padding-left: 5px;
        }

        .padding-left-10px {
            padding-left: 10px;
        }

        .redlined-text {
            color: $bittenred-6;
            text-decoration: line-through;
        }
    }
}

.document-content {
    table {
        .paragraph-wrapper {
            margin: 0;
            padding: 4px;

            .paragraph-content {
                padding: 0;
                margin-left: 8px;
            }
        }

        td {
            text-align: center;
        }
    }
}
