@use '@thought-river/ui-components/dist/colors.scss' as *;

$toolbar-height: 54px;

.toolbar {
    display: flex;
    align-items: center;
    min-height: $toolbar-height;
    max-height: $toolbar-height;
    border-bottom: 1px solid $vintagevelvet-1;
    padding: 10px 48px;
    box-sizing: border-box;

    .reportNameWrapper {
        display: flex;
        gap: 24px;
        align-items: center;
    }

    .overflowMenuButton {
        margin-left: auto;
    }
}
