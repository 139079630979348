.banner-wrapper {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 11;

    .banner-title-text {
        align-items: center;
        display: flex;
        gap: 8px;
    }
}
