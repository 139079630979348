@use '@thought-river/ui-components/dist/colors.scss' as *;

.issues-report-details {
    background: $bluechaos-1;
    padding: 30px 20px;
    display: flex;
    gap: 8px;

    & > * {
        flex-basis: 50%;
    }

    label {
        width: 100px;
    }
}
