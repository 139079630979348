@use '@thought-river/ui-components/dist/colors.scss' as *;

.wrapper {
    display: flex;
    gap: 8px;
    flex: 1;
    align-items: center;

    .progressBar {
        display: flex;
        width: calc(100% - 40px);
        height: 12px;
        border-radius: 16px;
        overflow: hidden;

        .resolved {
            background-color: $bluechaos-7;
        }

        .unresolved {
            background-color: $vintagevelvet-2;
        }
    }
}
