@use '@thought-river/ui-components/dist/colors.scss' as *;

.issue-pane-tabs {
    background: white;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;

    .categories-multi-select {
        margin-bottom: 10px;
    }

    .issue-form-input {
        border: none;
        border-bottom: 1px solid $battlegrey-3;
        box-sizing: border-box;
        color: $maastrichtblue-8;
        font-size: 1.2em;
        font-weight: bold;
        padding: 6px 0 8px 10px;
        width: 100%;

        &[readonly] {
            cursor: not-allowed;
        }

        &::placeholder {
            font-weight: normal;
        }

        &:focus {
            outline: none;
        }
    }

    .description-textarea {
        margin-top: 12px;
        width: 100%;
    }

    .description {
        color: $battlegrey-7;
        font-size: 0.9em;
    }

    .issue-form-label {
        color: $battlegrey-7;
        font-size: 0.8em;
    }
}
