@use '@thought-river/ui-components/dist/colors.scss' as *;

.issue-pane-intel-tab {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .issue-themes,
    .issue-paragraph-familiarity {
        margin-top: 20px;
    }

    .issue-themes-missing,
    .issue-paragraph-familiarity {
        .label {
            font-weight: 600;
            font-size: 12px;
            align-self: center;
            color: $font-color;
            margin-bottom: 10px;
        }
    }
}
