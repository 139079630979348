.bulk-reprocess-modal {
    color: $battlegrey-10;
    width: 550px;
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
}
