@use '@thought-river/ui-components/dist/colors.scss' as *;
@import '../../../styles/animations.scss';

.issues-report-modal {
    .copy-link-wrapper {
        display: flex;
        margin-top: 20px;

        > * {
            align-self: center;
        }

        .animated-btn {
            margin-left: 4px;
        }

        .link-input {
            width: calc(100% - 100px);
            margin-right: 10px;

            > * {
                color: $vintagevelvet-8;
                opacity: 0.6;
            }

            input {
                cursor: text;
                font-size: 0.75rem;
            }

            .link-icon {
                font-size: 1.3rem;
            }
        }
    }
}
