@use '@thought-river/ui-components/dist/colors.scss' as *;

.dropdown-group {
    .dropdowns {
        display: flex;
        flex-wrap: wrap;

        .dropdown {
            margin-bottom: 10px;
        }
    }

    .delete-dropdown-icon {
        color: $battlegrey-3;
        cursor: pointer;
        vertical-align: middle;
    }

    .add-dropdown-label {
        cursor: pointer;
        color: $bluechaos-5;
        display: inline-block;

        .dropdown-add-icon {
            vertical-align: middle;
        }

        .dropdown-add-label {
            vertical-align: middle;
            font-weight: normal;
        }
    }
}
