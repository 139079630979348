.party-correction-wrapper {
    height: 100%;
    position: relative;
    width: 100%;
}

.party-correction-container {
    height: calc(100% - #{$header-height});
    position: relative;
    width: 100%;

    .party-correction-inner {
        height: 100%;
    }

    .content {
        display: flex;
        height: 100%;
        flex-direction: column;
        flex-grow: 1;
    }

    .party-correction-header {
        text-align: right;
        padding: 6px 25px;
        border-bottom: 1px solid $battlegrey-3;

        .party-correction-form {
            text-align: right;

            .caption {
                color: $battlegrey-7;
                margin-right: 20px;
                vertical-align: middle;
            }
        }
    }

    .pane-divider {
        display: none;
        order: 1;
    }
}

@media only screen and (min-width: $screen-xs) {
    .header {
        .logo-img {
            max-width: 200px;
        }

        .page-title {
            width: 250px;
        }
    }
}

@media only screen and (min-width: $screen-sm) {
    .party-correction-container {
        .content {
            flex-direction: row;
        }

        .pane-divider {
            display: block;
        }
    }
}
