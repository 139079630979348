@use '@thought-river/ui-components/dist/colors.scss' as *;

@keyframes selectedToUpdated {
    0% {
        color: $maastrichtblue-8;
    }
    50% {
        background: $feistypink-4;
        color: white;
    }
    100% {
        color: $maastrichtblue-8;
    }
}

@keyframes unselectedToUpdated {
    0% {
        color: $maastrichtblue-8;
    }
    50% {
        background: $feistypink-4;
        color: white;
    }
    100% {
        color: $maastrichtblue-8;
    }
}

@keyframes textSelectedToUpdated {
    0% {
        color: $maastrichtblue-8;
    }
    50% {
        color: white;
    }
    100% {
        color: $maastrichtblue-8;
    }
}

@keyframes textUnselectedToUpdated {
    0% {
        color: $maastrichtblue-8;
    }
    50% {
        color: white;
    }
    100% {
        color: $maastrichtblue-8;
    }
}

@keyframes updatedBadgeAnimation {
    0% {
        opacity: 0;
    }
    1% {
        opacity: 1;
    }
    99% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.summary-property {
    color: $font-color;
    cursor: pointer;
    padding: 4px 20px 4px 4px;
    box-sizing: border-box;
    width: 100%;
    min-height: 68px;
    align-items: center;
    height: fit-content;

    .summary-property-content {
        display: flex;
        flex-grow: 1;
        gap: 10px;
        align-items: center;

        label {
            max-width: 50%;
            width: 100%;
        }

        .details-btn {
            visibility: hidden;
        }

        .input {
            flex-grow: 1;
            min-width: 135px;

            .MuiFormHelperText-root {
                display: flex;
                gap: 5px;
                color: $maastrichtblue-7 !important; //override searchable dropdown helper text color
                align-items: center;

                .MuiSvgIcon-root {
                    font-size: 16px;
                }
            }
        }
    }

    > * {
        align-self: center;
    }

    &.selected {
        background: $bluechaos-1;

        .details-btn {
            visibility: visible;
        }

        &.updated {
            animation: selectedToUpdated 2s linear 1 forwards;

            .missing-answer-help-icon {
                animation: textSelectedToUpdated 2s linear 1 forwards;
            }
        }

        .summary-property-question {
            display: block;
            margin-top: 4px;
        }
    }

    &.updated {
        animation: unselectedToUpdated 2s linear 1 forwards;
    }

    .badges-wrapper {
        display: flex;
        position: relative;
        align-items: center;
        height: 34px;
        min-width: 125px;
        gap: 5px;
        justify-content: flex-end;

        .new-badge {
            opacity: 0;
            display: none;

            &.show {
                display: block;
                animation: updatedBadgeAnimation 60s linear 1 forwards;
            }
        }

        .updated-badge {
            opacity: 0;
            display: none;

            &.show {
                display: block;
                animation: updatedBadgeAnimation 15s linear 1 forwards;
            }
        }

        .updated-clauses-badge {
            opacity: 0;
            display: none;

            &.show {
                display: block;
                animation: updatedBadgeAnimation 6s linear 1 forwards;
            }

            .badge-content {
                display: flex;

                > * {
                    align-self: center;
                }

                .link-icon {
                    font-size: 0.75rem;
                    margin-left: 2px;
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .summary-property-question {
        color: $bluechaos-7;
        font-size: 0.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: none;
        white-space: nowrap;
    }
}

.help-icon-tooltip {
    .reprocess-tooltip-content {
        text-align: left;

        .link-text {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
