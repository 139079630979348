@use '@thought-river/ui-components/dist/colors.scss' as *;

.splash-loader-wrapper {
    color: $battlegrey-7;
    height: 100%;
    position: relative;
    width: 100%;

    &.small {
        .splash-loader-content {
            font-size: 12px;
        }
    }

    .splash-loader-content {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -40%);
        text-align: center;
        color: $bluechaos-5;
        text-align: center;
        margin-top: 12px;
        font-size: 1.2em;

        > * {
            vertical-align: middle;
        }

        .loader {
            width: 15px;
            height: 15px;
        }

        .loader-text {
            margin-left: 10px;
        }

        .error-message {
            color: $vintagevelvet-8;
        }
    }

    .message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.5em;
        line-height: 1.5em;
    }
}
