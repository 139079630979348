@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate-out {
    from {
        opacity: 1;
    }

    to {
        transform: rotate3d(0, 0, 1, 200deg);
        opacity: 0;
    }
}

@keyframes fade-out-bottom-right {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(30%, 30%, 0);
    }
}

@keyframes rotate-in {
    from {
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0;
    }

    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes zoom-in {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes hide-element {
    to {
        max-height: 0;
        max-width: 0;
    }
}

@keyframes slide-in-right {
    from {
        transform: translate3d(100%, 0, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }
}

@keyframes slide-out-right {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(100%, 0, 0);
    }
}

@keyframes property-badge-slide-in-right {
    from {
        transform: translate3d(16%, 0, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }
}

@keyframes property-badge-slide-out-right {
    from {
        transform: translate3d(16%, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(0, 0, 0);
    }
}
