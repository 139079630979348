@use '@thought-river/ui-components/dist/colors.scss' as *;

.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    position: relative;
    background-color: #f7f7f7;
}

.outlineContainer {
    display: flex;
    flex-grow: 1;
    min-height: 0;
}

.documentWrapper {
    padding: 20px 40px 20px 0;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;

    &.noPadding {
        padding: 0;
    }
}

.documentPage {
    box-sizing: border-box;
    padding: 40px 30px;
    background-color: #ffffff;
    position: relative;
    margin: 0 auto;
    width: fit-content;
    max-width: 1140px;

    table {
        border-collapse: collapse;
        position: relative;
        width: 100%;

        td {
            padding: 5px;
        }
    }

    // Set margin-right for top level tables as padding will be ignored
    & > table {
        width: calc(100% - 30px);
        margin-right: 30px;
    }

    :global(.TR_deleted_paragraph) {
        color: $bittenred-6;
        text-decoration: line-through;
    }

    :global(.TR_inserted_paragraph) {
        color: $thickgreen-9 !important;
        text-decoration: underline;
    }

    mark {
        background: $bluechaos-1;
        scroll-margin-top: 50px;
    }
}
