@use '@thought-river/ui-components/dist/colors.scss' as *;

.link-popover {
    background: white;
    color: $vintagevelvet-8;
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 0 1px rgba(9, 30, 66, 0.31),
        0 4px 8px -2px rgba(9, 30, 66, 0.25);
    min-width: 300px;

    .link-input {
        color: $vintagevelvet-8;
        display: block;
        width: 100%;

        .MuiInputBase-root {
            font-size: 0.9rem;
            padding-left: 28px;
            width: 100%;
        }
    }

    .MuiInputAdornment-positionStart {
        margin-right: 4px;

        .link-icon {
            color: $battlegrey-5;
            font-size: 1.3em;
            left: 5px;
            margin-right: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
