@use '@thought-river/ui-components/dist/colors.scss' as *;

.MuiTablePagination-toolbar {
    color: $vintagevelvet-8;
}

.pagination {
    flex-shrink: 0;

    .MuiPaginationItem-root {
        font-family: 'Lato', 'Segoe UI', Calibri, Arial, sans-serif;
        height: 25px;
        min-width: 25px;

        &.Mui-selected {
            background: $bluechaos-5;
        }
    }
}
