@use '@thought-river/ui-components/dist/colors.scss' as *;

.custom-toolbar-option {
    margin: 0 4px 4px;
    position: relative;
    user-select: none;

    .custom-tooltip-wrapper {
        display: block;
    }

    .toolbar-label-wrapper {
        display: block;

        &.clickable {
            cursor: pointer;

            &:hover {
                .toolbar-label {
                    border-radius: 4px;
                    background: rgba(9, 30, 66, 0.08);
                    transition-duration: 0s, 0.15s;
                }
            }
        }

        .toolbar-label {
            display: block;

            .toolbar-icon {
                display: block;
                font-size: 1.2rem;

                & .emoji-icon {
                    font-size: 1.1rem;
                }
            }
        }
    }
}
