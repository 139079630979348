$scrollbar-node-width: 4px;
$scrollbar-node-width-small: 4px;

.heatmap-scrollbar-wrapper {
    padding: 15px 8px 15px 15px;

    .heatmap-scrollbar {
        position: relative;
        height: 100%;

        .backbone {
            height: 100%;
            position: absolute;
            width: 1px;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
            background: $battlegrey-5;
        }

        &.active-selection {
            .paragraph-node {
                opacity: 0.4;

                &.highlighted,
                &.selected {
                    opacity: 1;
                }
            }
        }

        .slider {
            background: $battlegrey-9;
            left: 0;
            position: absolute;
            width: 1px;

            &:before {
                background: $battlegrey-9;
                content: '';
                height: 1px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                top: 0;
                width: 10px;
                z-index: 1;
            }

            &:after {
                background: $battlegrey-9;
                content: '';
                height: 1px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
                bottom: 0;
                width: 10px;
                z-index: 1;
            }
        }

        .paragraph-node {
            border-radius: $scrollbar-node-width;
            cursor: pointer;
            margin: 0 auto;
            position: relative;
            width: $scrollbar-node-width;
            height: 18px; // TODO: Discuss with team

            &.small {
                border-radius: $scrollbar-node-width-small;
                width: $scrollbar-node-width-small;

                &.selected {
                    &:after {
                        border-radius: $scrollbar-node-width-small;
                    }
                }
            }

            &.in-theme {
                background-color: $battlegrey-6;
            }

            &.high {
                background-color: $high-primary-color;
            }

            &.low {
                background-color: $low-primary-color;
            }

            &.medium {
                background-color: $medium-primary-color;
            }

            &.ok {
                background-color: $ok-primary-color;
            }

            &.showstopper {
                background-color: $showstopper-primary-color;
            }

            &.unfamiliar {
                background-color: $unfamiliar-primary-color;
            }

            &.uncommon {
                background-color: $uncommon-primary-color;
            }

            &.common {
                background-color: $common-primary-color;
            }

            &.template {
                background-color: $template-primary-color;
            }

            &.no-severity {
                background-color: $none-primary-color;
            }

            &.selected {
                &:after {
                    background: transparent;
                    border: 2px solid #ffe710;
                    content: '';
                    height: 100%;
                    left: 50%;
                    position: absolute;
                    top: -2px;
                    transform: translateX(-50%);
                    width: 100%;
                    z-index: 1;
                    border-radius: $scrollbar-node-width;
                }
            }

            &.highlighted {
                background: #ffe710;
            }
        }
    }
}
