@use '@thought-river/ui-components/dist/colors.scss' as *;

.description {
    color: $font-color;
    padding: 0 8px;
    margin-bottom: 8px;
}

.value {
    display: flex;
    gap: 8px;
    align-items: center;
    min-width: 0;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
