@use '@thought-river/ui-components/dist/colors.scss' as *;

.title {
    display: flex;
    gap: 4px;
    align-items: center;
}

.icon {
    color: $bittenred-7;
}
