@use '@thought-river/ui-components/dist/colors.scss' as *;

.wrapper {
    overflow: auto;
    padding: 0 48px 0 0;
    margin-right: -48px;

    .seperator {
        width: 100%;
        height: 1px;
        background: $battlegrey-4;
        margin: 8px 0 16px 0;
    }

    .list {
        display: flex;
        flex-direction: column;

        .item {
            background-color: $battlegrey-3;
            border-radius: 4px;
            padding: 8px 4px;
            box-sizing: border-box;
            margin-bottom: 8px;
            cursor: pointer;

            &:hover {
                .itemContent {
                    .column {
                        .buttonsContainer {
                            .removeButton {
                                visibility: visible;
                            }
                        }
                    }
                }
            }

            &.invisibleItem {
                background-color: $battlegrey-2;
                padding-left: 24px;
            }

            .itemContent {
                width: 100%;
                display: flex;
                gap: 8px;
                flex-direction: column;

                .column {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    height: 22px;

                    .buttonsContainer {
                        margin-left: auto;
                        display: flex;
                        gap: 4px;

                        .visibilityButton {
                            .visibleIcon {
                                color: $bluechaos-4;
                            }
                            .invisibleIcon {
                                color: $vintagevelvet-4;
                            }
                        }

                        .removeButton {
                            margin-left: auto;
                            visibility: hidden;

                            .removeIcon {
                                color: $vintagevelvet-8;
                            }
                        }
                    }
                }

                .filters {
                    height: 10px;
                    font-size: 10px;
                    color: $purplecabbage-4;
                }
            }
        }
    }
}
