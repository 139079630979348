.custom-popover-wrapper {
    &.hidden {
        display: none;
    }

    .custom-popover {
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
            rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
            rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
        z-index: 11;
    }

    .custom-popover-overlay {
        background: transparent;
        left: 0;
        top: 0;
        position: fixed;
        z-index: 10;
        width: 100%;
        height: 100%;
    }
}
