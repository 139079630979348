@use '@thought-river/ui-components/dist/colors.scss' as *;

.treeView {
    position: relative;
    font-size: 12px;
}

.treeItem {
    scroll-margin-top: 150px;

    :global(.MuiTreeItem-content) {
        padding: 0px 4px;

        &:hover {
            background: transparent;
            color: $bluechaos-7;
        }

        &:global(.Mui-focused),
        &:global(.Mui-selected) {
            background: transparent;
        }
    }

    &.current > :global(.MuiTreeItem-content) {
        color: $bluechaos-7;
    }

    :global(.MuiTreeItem-label) {
        font-size: 12px;
        line-height: 24px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    :global(.MuiTreeItem-iconContainer) {
        display: none;
    }
}
