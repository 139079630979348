@use '@thought-river/ui-components/dist/colors.scss' as *;

$max-row-height: 300px;

.hybrid-report-data-grid-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .MuiDataGrid-root {
        border: none !important; // Overrides the deafult Material UI Data Grid border settings

        .MuiDataGrid-main {
            color: $vintagevelvet-8;

            .MuiDataGrid-columnHeaders {
                background: $battlegrey-2;
                border: none;

                .MuiDataGrid-columnHeaderTitle {
                    font-weight: bold;
                }
            }

            .MuiDataGrid-cell {
                border: none;
            }
        }

        .MuiDataGrid-footerContainer {
            border: none;
        }
    }

    .MuiDataGrid-columnHeader[data-field='user_feedback'] {
        display: none;
    }

    .table-cell > * {
        min-width: 100%;
        min-height: 100%;
    }

    .familiarity-cell {
        display: flex;

        * {
            align-self: center;
            line-height: 20px;
            vertical-align: middle;
        }
    }

    .importance-cell,
    .status-cell,
    .familiarity-cell {
        display: flex;

        * {
            align-self: center;
            line-height: 20px;
            vertical-align: middle;
        }

        .tr-icon.MuiSvgIcon-root {
            margin-right: 5px;
        }
    }

    .linked-clauses-cell {
        display: flex;

        &.missing-data-cell {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &.expanded {
            flex-wrap: wrap;

            > * {
                margin-bottom: 6px;
            }
        }

        &.collapsed {
            white-space: nowrap;
        }

        > * {
            align-self: center;
        }

        .chip {
            margin-left: 6px;
        }

        .clause-number {
            margin-right: 6px;

            &:last-of-type {
                margin-right: 0;
            }
        }

        .toggle-clauses-arrow {
            color: $bluechaos-5;
            cursor: pointer;
            font-size: 1.2em;
        }
    }

    .linked-clauses-text-cell {
        .linked-clause-text + .linked-clause-text {
            margin-top: 10px;
        }
    }

    .linked-clauses-text-cell,
    .suggestions-cell {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        &.linked-clauses-placeholder-cell,
        &.missing-data-cell {
            justify-content: center;
        }
    }

    .non-editable-cell {
        cursor: not-allowed !important;
    }

    .brand-nested-data-grid {
        td {
            text-align: center;

            .sign-up-url {
                color: $bluechaos-5;
                display: block;
            }

            > * {
                vertical-align: middle;
            }
        }
    }

    .rte-container {
        width: 100%;
    }

    .table-row {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        position: relative;
        max-height: $max-row-height !important; //overide default MUI style

        &:hover {
            .table-cell {
                &.user-feedback {
                    .user-feedback-cell-content {
                        display: block;
                    }
                }
            }
        }

        .table-cell {
            display: flex;
            font-family: 'Lato', 'Segoe UI', Calibri, Arial, sans-serif;
            line-height: 1.25rem;
            overflow: auto;
            white-space: normal;
            padding: 10px;
            max-height: inherit !important; //overide default MUI style
            align-items: center;

            &:not(.MuiDataGrid-cell--editing) {
                &.description {
                    white-space: pre-wrap;
                }

                .rte-editor {
                    white-space: normal;
                    max-height: inherit;
                }
            }

            p {
                margin: 0;

                &:first-of-type {
                    margin-top: 1em;
                }

                &:last-of-type {
                    margin-bottom: 1em;
                }
            }

            &.MuiDataGrid-cell--editing {
                justify-content: center;
                overflow: hidden;
                padding: 0 10px;

                ~ .table-cell {
                    &.user-feedback {
                        .user-feedback-cell-content {
                            display: none; // Hides the user feedback UI when a cell is being edited
                        }
                    }
                }

                &.description,
                &.external-notes {
                    &.rte-enabled {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }

                &.linked-clause {
                    position: relative;
                }

                .MuiInput-input {
                    font-size: 0.875em;
                    line-height: 1.25rem;
                }

                &.status,
                &.importance {
                    padding: 0;
                }
            }

            &.user-feedback {
                padding: 0;

                .user-feedback-cell-content {
                    display: none;
                    min-width: 49px;
                    min-height: 0;
                    padding: 4px;

                    .voting {
                        justify-content: center;
                    }
                }
            }
        }
    }

    /** MUI Override **/

    .MuiDataGrid-main {
        .MuiDataGrid-columnHeaders {
            border-bottom: 1px solid rgba(224, 224, 224, 1);

            .MuiDataGrid-columnHeader {
                font-family: 'Lato', 'Segoe UI', Calibri, Arial, sans-serif;
                padding: 0 10px;
                text-transform: uppercase;
                user-select: none;
            }

            .MuiDataGrid-columnHeader--sorted {
                color: $bluechaos-5;
            }
        }
    }
}

// Overrides the Linked Clauses dropdown options
.issue-clause-number-options {
    min-width: 400px;
}

.suggestion-placeholder-tooltip {
    margin-bottom: 0 !important; // Overrides the MUI tooltip position
}
