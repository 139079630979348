@use '@thought-river/ui-components/dist/colors.scss' as *;

$toolbar-height: 54px;

.viewReportPage {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .grid {
        padding: 14px 48px;
        height: calc(100% - $toolbar-height);
        box-sizing: border-box;

        .cellContent {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $font-color;
            text-decoration: none;
            font-size: 14px;

            &.contractNameCell {
                display: flex;
                gap: 8px;
                align-items: center;
            }
        }

        .headerText {
            line-height: normal;
            white-space: break-spaces;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
}
