@use '@thought-river/ui-components/dist/colors.scss' as *;

.issue-pane {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    .issue-pane-dropdowns {
        display: flex;
        margin-bottom: 15px;

        .MuiInputBase-root {
            cursor: pointer;
        }

        .issue-severity-dropdown,
        .issue-status-dropdown {
            height: 28px;
        }
    }

    .issue-title-wrapper {
        align-items: center;
        display: flex;
        margin-bottom: 15px;
        width: 100%;

        > * {
            align-self: center;
        }
    }
}

.issue-pane-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.issue-pane-header-badges {
    display: flex;
    align-items: center;
    gap: 8px;

    .locked-version-icon {
        font-size: 18px;
    }
}

.issue-pane-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
