.headerSkeleton {
    font-size: 32px;
    flex-grow: 1;
}

.navigationSkeleton {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 20px 30px;
    font-size: 24px;
}

.navigationLabel {
    flex-grow: 1;
}

.navigationControls {
    width: 40px;
}

.contentSkeleton {
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 24px;
}

.paragraphSkeleton {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
