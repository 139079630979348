@use '@thought-river/ui-components/dist/colors.scss' as *;

.checkbox {
    height: 38px;
    width: 100%;
    justify-content: center;
    display: flex;
    padding: 0 0 0 15px;

    &:hover {
        background-color: $bluechaos-1;
    }

    .label {
        display: flex;
        gap: 8px;
        align-items: center;

        .informationTooltip {
            font-size: 16px;
            color: $bluechaos-7;
        }
    }
}
