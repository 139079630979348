@use '@thought-river/ui-components/dist/colors.scss' as *;

.settings-menu {
    color: $vintagevelvet-8;

    .MuiPaper-root {
        top: 48px !important; // overrides the default menu position
    }

    .menu-content {
        display: flex;

        .menu-column {
            &:not(:first-of-type) {
                margin-left: 20px;
            }
        }
    }

    .menu-group {
        &.disabled {
            .menu-group-items {
                cursor: not-allowed;
            }
        }

        .menu-group-title {
            border-bottom: 1px solid $battlegrey-3;
            color: $vintagevelvet-8;
            cursor: default;
            font-size: 0.9rem;
            font-weight: bold;
            transition: none;

            &:hover {
                background: white;
            }

            .MuiTouchRipple-root {
                display: none;
            }

            &.MuiListItem-root {
                &.Mui-disabled {
                    opacity: 1;
                }
            }

            .menu-group-badge {
                background-color: $bluechaos-5;
                border-radius: 12px;
                color: white;
                cursor: pointer;
                font-weight: 400;
                margin-left: 5px;
                padding: 2px 8px;
                font-size: 0.8rem;
            }
        }
    }

    .menu-item {
        padding: 0;

        &.MuiListItem-root {
            &.Mui-disabled {
                cursor: not-allowed;
            }
        }

        &:hover {
            background: $bluechaos-1;
        }

        a {
            color: $vintagevelvet-8;
            display: flex;
            padding: 4px 16px;
            width: 100%;

            &:hover {
                text-decoration: none;
            }
        }

        .menu-item-icon {
            color: $vintagevelvet-8;
            min-width: auto;

            svg {
                align-self: center;
                font-size: 1.1em;
            }
        }

        .menu-item-label {
            margin-left: 8px;

            span {
                font-size: 0.9rem;
            }
        }
    }
}
