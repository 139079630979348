@use '@thought-river/ui-components/dist/colors.scss' as *;

.paragraph-suggestion {
    .suggestion-box {
        box-sizing: border-box;
        border: 1px solid $battlegrey-4;
        border-radius: 8px;
        padding: 10px;

        .suggestion-box-label {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            color: $font-color;
            font-weight: 600;
            font-size: 12px;
        }

        p {
            margin: 0;
        }
    }

    .copy-button-wrapper {
        text-align: right;
        margin: 10px 0;

        .btn {
            text-transform: uppercase;

            &.btn-primary {
                &:not(:disabled) {
                    &:hover {
                        background-color: $bluechaos-5;
                    }
                }

                svg {
                    height: 14px;
                }
            }
        }
    }
}
