@import '@thought-river/ui-components/dist/colors.scss';

.versionItem {
    color: $font-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    cursor: pointer;
    gap: 16px;

    &:hover:not(:has(*:hover)):not(&.selected) {
        background-color: $vintagevelvet-1;
    }

    &.selected {
        background-color: $bluechaos-5;
        color: #ffffff;

        .threeDotIcon {
            color: #ffffff;
        }
    }
}

.status {
    display: flex;
    align-items: center;
    gap: 8px;
    font-style: italic;
}

.rightAlign {
    display: flex;
    align-items: center;
    gap: 16px;
}
