@use '@thought-river/ui-components/dist/colors.scss' as *;

.modal-dialog .modal-dialog-window.share-negotiation-modal-wrapper {
    width: 550px;
    height: 490px;
    padding: 0;

    .modal-dialog-window-header {
        padding: 16px 24px;
        margin-bottom: 0;
    }

    .modal-dialog-title {
        color: $vintagevelvet-8;
    }

    .modal-dialog-window-content {
        overflow: hidden;
    }

    .selected-users-list {
        list-style: none;
        padding: 0 24px;
        margin: 0;
        height: calc(100% - 95px);
        overflow-y: auto;

        .MuiInputBase-root::before,
        .MuiInputBase-root::after {
            content: none;
        }

        .MuiSelect-select:focus {
            background-color: transparent;
        }
    }

    .invite-users-dropdown {
        padding: 0 24px;
        box-sizing: border-box;

        label {
            width: 90px;
        }
    }

    .modal-footer {
        padding: 15px 24px;
        display: flex;
        justify-content: space-between;
    }

    .MuiButtonBase-root.animated-btn {
        background-color: transparent;
        box-shadow: none;
        color: $vintagevelvet-8;
        justify-content: left;
        margin-left: -10px;
    }
}
