@use '@thought-river/ui-components/dist/colors.scss' as *;

.paragraph-suggestions {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    min-height: 0;
    position: relative;
    overflow: auto;

    .paragraph-suggestions-header {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        color: $font-color;
        font-weight: 600;
        font-size: 12px;
        align-items: center;

        .suggestions-locations {
            display: flex;
            align-items: center;

            .location-button {
                padding: 0;

                svg {
                    font-size: 18px;
                    color: $font-color;
                }
            }
        }
    }

    .missing-suggestions-alert {
        margin: 15px 0;
    }

    .paragraph-suggestions-list {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
        height: 0;
        margin-top: 15px;
        gap: 15px;
    }

    .loading-wrapper {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;

        .loader {
            height: 64px;
            left: calc(50% - 32px);
            position: absolute;
            top: calc(50% - 32px);
            width: 64px;
        }
    }
}
