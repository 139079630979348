.party {
    color: $battlegrey-7;
    cursor: pointer;
    display: flex;
    margin-bottom: 3px;
    user-select: none;

    &.highlighted {
        .party-info {
            background: $bluechaos-1;
        }
    }

    &.selected {
        .party-info {
            background: $bluechaos-5;
            color: white;
        }

        .flip-controls {
            .flip-control {
                display: inline-block;
            }
        }

        .variant-type {
            .variant-type-icon {
                color: white;
            }
        }
    }

    .action-controls {
        padding: 1px 0;
        position: relative;
        line-height: 1.4em;

        .control-icon {
            color: $risk-unscored;
            font-size: 1.3em;
            margin-left: 2px;
            vertical-align: middle;

            &.alt-hover {
                &:hover {
                    color: $orangedanger-5;
                }
            }

            &:first-of-type {
                margin-left: 6px;
            }

            &:hover {
                color: $bluechaos-5;
            }
        }
    }

    .flip-controls {
        float: right;
        min-width: 28px;
        position: relative;

        .flip-control {
            display: none;
            position: absolute;

            .flip-icon {
                font-size: 1.3em;
                line-height: 1em;
                margin-left: -4px;
                vertical-align: middle;

                &.disabled {
                    cursor: default;
                    opacity: 0.5;
                }
            }

            &.flip-next {
                right: 0;

                .flip-icon {
                    transform: rotate(-90deg);
                }
            }

            &.flip-prev {
                .flip-icon {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .party-info {
        border-radius: 5px;
        display: inline-block;
        flex-grow: 1;
        min-height: 17.72px;
        padding: 3px 5px;
        transition: background $time-normal ease;

        .party-name {
            vertical-align: middle;
        }
    }

    .variant-type {
        display: inline-block;
        height: 1em;
        margin-left: 10px;
        position: relative;
        vertical-align: middle;

        .variant-type-icon {
            color: $risk-unscored;
            cursor: pointer;
            font-size: 1.3em;
            position: absolute;
            top: -2.75px;
            vertical-align: middle;
        }
    }
}

.parties-group {
    &.collapsible {
        .party {
            &.highlighted {
                .party-info {
                    background: $orangedanger-1;
                }
            }

            &.selected {
                .party-info {
                    background: $orangedanger-5;
                }
            }
        }
    }
}
