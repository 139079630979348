.delete-version-modal {
    .delete-version-modal-title {
        margin-bottom: 10px;
    }

    .version-list-wrapper {
        border: 1px solid $battlegrey-5;
        border-radius: 4px;
        box-sizing: border-box;

        .version-list-headers,
        .version-row {
            display: flex;
            padding: 8px 12px;

            > * {
                padding: 0 6px;
                line-height: 1em;
                vertical-align: middle;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }

        .version-list-headers {
            background: $battlegrey-2;
            color: $battlegrey-7;
            font-weight: bold;
            margin-bottom: 4px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        .version-list {
            height: 110px;
            overflow-y: auto;
            width: 100%;
        }

        .version-number-header,
        .version-number-cell {
            width: 45px;
            text-align: center;
        }

        .file-name-header,
        .file-name-cell {
            flex: 1 1 0;
            padding-left: 20px;

            .version-name {
                display: inline-block;
                overflow: hidden;
                max-width: 300px;
                text-overflow: ellipsis;
                vertical-align: middle;
                white-space: nowrap;
            }
        }

        .created-at-header .created-at-cell {
            flex: 1 1 0;
            text-align: right;
            vertical-align: middle;
        }
    }
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
}
