@use '@thought-river/ui-components/dist/colors.scss' as *;

.issues-report-toolbar {
    display: flex;

    .toolbar-btn {
        align-self: center;
        margin-left: 6px;

        &:first-of-type {
            margin-left: auto;
        }
    }

    .issue-views-dropdown-wrapper {
        min-width: 268px;
    }
}
