@use '@thought-river/ui-components/dist/colors.scss' as *;

.list {
    padding: 0;

    .item {
        height: 38px;
        line-height: 22px;
        padding: 0 24px;
        cursor: pointer;
        border-radius: 4px 0px 0px 4px;
        justify-content: space-between;

        &:hover {
            background-color: $bluechaos-1;
        }

        &.selected {
            background-color: $bluechaos-1;
            border-right: 3px solid $bluechaos-7;
        }

        &:not(.selected) {
            padding-right: 27px;
        }

        .label {
            display: flex;
            gap: 8px;
            min-width: 0;
            align-items: center;
        }

        .count {
            font-size: 10px;
            color: $vintagevelvet-4;
        }
    }
}
