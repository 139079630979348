@use '@thought-river/ui-components/dist/colors.scss' as *;

.list {
    height: 100%;
}

.item {
    user-select: none;
    display: flex;

    &:hover {
        .drag-handle {
            opacity: 1;
        }
    }

    &.is-dragging {
        background: $battlegrey-2;

        .drag-handle {
            opacity: 1;
        }
    }

    .drag-handle {
        width: 17px;
        height: 22px;
        display: flex;
        align-self: center;
        opacity: 0;

        .drag-handle-icon {
            color: $vintagevelvet-4;
        }
    }
}
