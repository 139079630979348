$toggle-enabled-background: white;
$toggle-enabled-color: $battlegrey-10;
$toggle-disabled-background: $battlegrey-2;
$toggle-disabled-color: $battlegrey-6;
$toggle-border-color: $battlegrey-3;

.toggle-bar {
    display: flex;
    flex-direction: row;
    border: 0;
    flex-wrap: wrap;
    border-radius: $radius-small;

    .toggle-bar-button {
        background: transparent;
        border: none;
        padding: 6px 10px;
        cursor: pointer;
        outline: none;
        transition: color $time-normal ease, background-color $time-normal ease;

        &.enabled {
            color: $toggle-enabled-color;
        }

        &.disabled {
            color: $toggle-disabled-color;
        }
    }
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
    .toggle-bar {
        flex-wrap: nowrap;
        border: 1px solid $toggle-border-color;

        .toggle-bar-button {
            border-right: 1px solid $toggle-border-color;

            &.enabled {
                background-color: $toggle-enabled-background;
                color: $toggle-enabled-color;
            }

            &.disabled {
                background-color: $toggle-disabled-background;
                color: $toggle-disabled-color;
            }

            &:first-child {
                border-radius: $radius-small 0 0 $radius-small;
            }

            &:last-child {
                border-radius: 0 $radius-small $radius-small 0;
                border-right: none;
            }
        }
    }
}
