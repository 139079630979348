.heatmap-search-bar {
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
}

@media only screen and (min-width: $screen-md) {
    .search-bar {
        width: 60px;
    }
}
