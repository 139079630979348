@use '@thought-river/ui-components/dist/colors.scss' as *;

.issues-report {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 0;
    padding: 30px 20px 0;
    position: relative;

    .issues-report-toolbar {
        margin: 20px 0;
    }
}
