@use '@thought-river/ui-components/dist/colors.scss' as *;

.property-link-widget {
    display: flex;
    position: absolute;
    right: 15px;
    top: 0;
    transform: translate(0, -70%);
    z-index: 1;

    &.highlighted {
        z-index: 2;
    }

    &.hovered {
        z-index: 3;
    }

    &.hide {
        visibility: hidden;
    }

    .link-clause-btn {
        .link-clause-icon {
            transform: rotate(-45deg);
        }
    }
}
