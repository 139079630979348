@use '@thought-river/ui-components/dist/colors.scss' as *;

.custom-emoji-picker {
    background: white;
    box-sizing: border-box;
    max-height: 200px;
    overflow: auto;
    padding: 4px 8px;

    .emoji-row {
        display: flex;
    }

    .emoji-option-wrapper {
        border-radius: 4px;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
        height: 26px;
        width: 26px;
        margin: 2px;
        padding: 1px;
        position: relative;

        &:hover {
            background: $battlegrey-3;
        }
    }

    .emoji-option {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
