.heatmap-pane {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    min-width: 830px;
    position: relative;

    .section-missing-risks {
        .risk-scores {
            top: 8px;
        }
    }

    .contract-body-spacer {
        height: 40px;
        margin: 0 30px;
        background: white;
    }
}
