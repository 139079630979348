.contract-list-table-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background: white;
    overflow: scroll;
    width: 100%;

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }

    .contract-list-table {
        width: 100%;
        background-color: white;
        border-collapse: collapse;
        border-spacing: 0;
        position: relative;

        th {
            background: white;
            box-sizing: border-box;
            color: $battlegrey-7;
            font-size: 1.1em;
            padding: 14px 7px;
            position: sticky;
            top: 0;
            z-index: 100;

            &:after {
                content: '';
                position: absolute;
                border-bottom: 1px solid $battlegrey-3;
                bottom: 0;
                left: 0;
                width: 100%;
            }

            &.connected-header {
                padding: 14px 0;

                .header-label {
                    background-color: $battlegrey-2;
                    height: 24px;
                    line-height: 24px;
                }

                &.connected-middle {
                    .header-label {
                        padding: 0 7px;
                    }
                }

                &.connected-left {
                    padding-left: 7px;

                    .header-label {
                        display: inline-block;
                        padding-left: 7px;
                        padding-right: 7px;
                        border-radius: 15px;
                    }
                }

                &.connected-right {
                    padding-right: 7px;

                    .header-label {
                        padding-left: 7px;
                        padding-right: 1px;
                        border-top-right-radius: 15px;
                        border-bottom-right-radius: 15px;
                    }
                }
            }

            &.progress-header {
                min-width: 215px;
                width: 215px;
            }

            &:first-of-type {
                padding-left: 14px;
            }

            &:last-of-type {
                padding-right: 14px;
            }

            &.contract-name-header {
                min-width: 185px;
            }

            &.counterparty-header {
                min-width: 135px;
            }

            &.date-header {
                min-width: 105px;

                .contract-list-icon {
                    float: right;
                }
            }

            &.dropdown-header {
                .header-label {
                    width: 24px;
                }
            }

            &.expand-row-header {
                .header-label {
                    width: 24px;
                }
            }

            &.reviewers-header {
                min-width: 104px;
            }

            &.new-version-header {
                width: 50px;
            }

            &.revision-header {
                min-width: 70px;
            }

            &.status-header {
                min-width: 95px;
            }

            &.stream-header {
                min-width: 85px;
            }

            .contract-list-table-subheader {
                margin-left: 5px;
                color: $battlegrey-5;
                font-weight: normal;
            }
        }

        tbody {
            tr {
                &:hover {
                    td {
                        background-color: #f4f7fd;

                        .create-revision-btn {
                            visibility: visible;
                        }
                    }
                }

                &.missing-reference-names-row {
                    background-color: $orangedanger-1;
                    &:hover,
                    td:hover {
                        &,
                        td {
                            background-color: $orangedanger-1;
                        }
                    }

                    .contract-name-cell {
                        font-style: italic;
                        color: $battlegrey-7;
                    }

                    .warning-cell {
                        line-height: 2em;

                        button {
                            float: right;
                        }
                    }

                    .warning-icon {
                        color: $orangedanger-1;
                    }

                    .contract-list-icon {
                        float: right;
                    }
                }

                &.contract-list-table-processing-row {
                    &:first-child {
                        td {
                            border-top: 0;
                        }
                    }

                    td {
                        color: $battlegrey-6;
                        font-style: italic;

                        border-top: 1px solid $battlegrey-3;
                        border-bottom: 1px solid $battlegrey-3;
                    }

                    .upload-progress-bar-wrapper {
                        display: flex;
                        height: 30px;

                        .upload-progress-bar {
                            width: 100%;
                            align-self: center;
                        }

                        .indeterminate-progress-bar {
                            width: 100%;
                            align-self: center;
                        }
                    }

                    + .contract-list-table-processing-row {
                        td {
                            border-top: 0;
                        }
                    }
                }

                &.contract-list-table-failed-row {
                    td {
                        background: $bittenred-2;

                        .failed-contract-icon {
                            background: $toast-background-error;
                            border-radius: 50%;
                            color: $toast-content-error;
                            padding: 5px;
                        }
                    }

                    &:hover {
                        td {
                            background: $bittenred-2;
                        }
                    }
                }

                td {
                    box-sizing: border-box;
                    padding: 14px 7px;
                    max-height: 60px;

                    &:first-of-type {
                        padding-left: 14px;
                    }

                    &:last-of-type {
                        padding-right: 14px;
                    }

                    &.new-version-cell {
                        min-width: 50px;
                    }

                    &.revision-cell {
                        min-width: 70px;
                    }

                    .create-revision-btn {
                        background-color: $bluechaos-5;
                        border: none;
                        border-radius: 15px;
                        color: white;
                        cursor: pointer;
                        display: inline-block;
                        font-weight: bold;
                        padding: 3px 6px 3px 21px;
                        position: relative;
                        visibility: hidden;

                        .contract-list-icon {
                            color: white;
                            font-size: 1.6em;
                            left: 0;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    &.contract-name-cell {
                        overflow: hidden;
                        max-width: 185px;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        &.clickable {
                            color: $bluechaos-5;
                            cursor: pointer;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    &.counterparty-cell {
                        overflow: hidden;
                        max-width: 135px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    &.stream-cell {
                        overflow: hidden;
                        max-width: 85px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    &.revision-bullet-cell {
                        .revision-bullet {
                            &::after {
                                content: '\2022';
                                display: inline-block;
                                line-height: 32px;
                                height: 32px;
                                vertical-align: middle;
                            }
                        }
                    }

                    .contract-list-table-reviewers {
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: flex-end;

                        .user-icon {
                            &:not(:last-of-type) {
                                margin-left: -7px;
                            }
                        }
                    }

                    .contract-row-menu-icon {
                        height: 32px;
                    }

                    .version-number {
                        vertical-align: middle;
                    }
                }

                &.version-row {
                    &.expanded {
                        display: table-row;
                    }

                    td {
                        display: none;
                    }

                    &.expanded {
                        display: table-row;

                        td {
                            display: table-cell;

                            &.hide-on-med,
                            &.hide-on-small,
                            &.hide-on-xs {
                                display: none;
                            }
                        }
                    }
                }

                .failed-progress-label {
                    color: $risk-high;
                }
            }
        }

        .contract-list-loader {
            height: 40px;
            line-height: 40px;
            padding: 10px 0 25px;
            text-align: center;

            .loader-wrapper {
                height: 40px;
                line-height: 40px;

                > * {
                    vertical-align: middle;
                }

                .loader {
                    height: 40px;
                    vertical-align: middle;
                    width: 40px;
                }
            }
        }

        .contract-list-icon {
            cursor: pointer;
            color: $bluechaos-5;
            transform: rotate(0deg);
            transition: transform 250ms ease;
            vertical-align: middle;

            &.action {
                background-color: $bluechaos-5;
                color: white;
                border-radius: 50%;
            }

            &.expanded {
                transform: rotate(90deg);
            }

            &.negative {
                color: $risk-high;
            }

            &.expand-icon {
                user-select: none;
            }
        }

        .delete-btn {
            padding: 0;

            .contract-list-btn-text {
                margin-left: 3px;
            }
        }

        .contract-list-btn-text {
            vertical-align: middle;
        }

        .hide-on-med,
        .hide-on-small,
        .hide-on-xs {
            display: none;
        }
    }

    .MuiLinearProgress-root.indeterminate-progress-bar,
    .indeterminate-progress-bar {
        border-radius: 4px;
        border: 1px solid $battlegrey-3;
        display: inline-block;
        height: 5px;
        margin-right: 16px;
        width: calc(100% - 22px);

        &.MuiLinearProgress-colorPrimary {
            background-color: $battlegrey-2;
        }

        .MuiLinearProgress-barColorPrimary {
            background-color: #78df86;
        }
    }

    .contract-list-round-icon {
        background: white;
        cursor: pointer;
        padding: 5px;
        border-radius: 50%;

        &.delete-icon {
            color: $bittenred-9;
            margin-right: 4px;
        }

        &.reprocess-icon {
            color: $bluechaos-7;
        }
    }
}

@media only screen and (min-width: $screen-xs) {
    .contract-list-table-container {
        .contract-list-table {
            th {
                &.contract-name-header {
                    min-width: 200px;
                }
            }

            tbody {
                tr {
                    td {
                        &.contract-name-cell {
                            max-width: 200px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $screen-sm) {
    .contract-list-table-container {
        .contract-list-table {
            tbody {
                tr {
                    &.version-row {
                        &.expanded {
                            td {
                                &.hide-on-xs {
                                    display: table-cell;
                                }
                            }
                        }
                    }
                }
            }

            .hide-on-xs {
                display: table-cell;
            }
        }
    }
}

@media only screen and (min-width: $screen-lg) {
    .contract-list-table-container {
        .contract-list-table {
            tbody {
                tr {
                    &.version-row {
                        &.expanded {
                            td {
                                &.hide-on-small {
                                    display: table-cell;
                                }
                            }
                        }
                    }
                }
            }

            .hide-on-small {
                display: table-cell;
            }

            th {
                &.connected-header {
                    &.connected-left {
                        .header-label {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $screen-xl) {
    .contract-list-table-container {
        .contract-list-table {
            tbody {
                tr {
                    &.version-row {
                        &.expanded {
                            td {
                                &.hide-on-med {
                                    display: table-cell;
                                }
                            }
                        }
                    }
                }
            }

            .hide-on-med {
                display: table-cell;
            }
        }
    }
}
