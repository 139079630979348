@use '@thought-river/ui-components/dist/colors.scss' as *;

.issue-familiarity {
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;

    .paragraph-familiarity {
        height: 30px;
        display: flex;

        .paragraph-number {
            min-width: 70px;
            color: $font-color;
            align-self: center;

            &:hover {
                cursor: pointer;
            }
        }

        .familiarity-bar {
            height: 16px;
            border-radius: 10px;
            align-self: center;

            &.unfamiliar {
                background: $unfamiliar-primary-color;
                color: $unfamiliar-primary-color;
            }

            &.uncommon {
                background: $uncommon-primary-color;
            }

            &.common {
                background: $common-primary-color;
            }

            &.template {
                background: $template-primary-color;
            }
        }
    }

    .familiarity-range-axis {
        margin-top: 10px;
        margin-left: 70px;
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: $font-color;
    }
}
