@use '@thought-river/ui-components/dist/colors.scss' as *;

.suggestions-sidebar-inner-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .sidebar-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: auto;
    }
}
