.upload-progress-bar {
    height: 5px;
    border: 1px solid $battlegrey-3;
    background-color: $battlegrey-2;
    border-radius: 4px;
    margin-right: 16px;

    .upload-progress-bar-indicator {
        height: 5px;
        border-radius: 4px;
        background-color: #78df86;
    }
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
}
