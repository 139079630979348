@use '@thought-river/ui-components/dist/colors.scss' as *;

.dual-list-editor {
    box-sizing: border-box;
    display: flex;
    padding: 10px 24px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;

    .list {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
        border: 1px solid $battlegrey-3;
        border-radius: 4px;
        padding: 15px 8px;
        min-height: 292.5px;
        min-width: 222px;
        height: 0;
        text-align: left;

        .list-items {
            overflow-y: auto;
        }

        .list-title {
            font-size: 0.8571428571428571em;
            margin-bottom: 10px;
            padding-left: 10px;
            text-transform: uppercase;
        }

        .draggable-label {
            background: $battlegrey-2;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            margin-bottom: 10px;
            padding: 6px 10px;
            transition: background-color 0.3s ease-in;
            color: $battlegrey-8;

            &:hover {
                background: rgb(233, 231, 231);
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            > * {
                align-self: center;
                vertical-align: middle;
            }

            .drag-label {
                margin-left: 1px;
                width: 100%;
                min-width: 0;
            }

            .drag-icon {
                opacity: 0.6;
            }
        }
    }
}
