@use '@thought-river/ui-components/dist/colors.scss' as *;

.wrapper {
    padding-left: var(--doc-padding-left, 0px);

    &.highlighted {
        background: $none-secondary-color;

        &.high {
            background: $high-secondary-color;
        }

        &.low {
            background: $low-secondary-color;
        }

        &.medium {
            background: $medium-secondary-color;
        }

        &.ok {
            background: $ok-secondary-color;
        }

        &.showstopper {
            background: $showstopper-secondary-color;
        }

        &.unscored {
            background: $none-secondary-color;
        }

        &.unfamiliar {
            background: $unfamiliar-secondary-color;
        }

        &.uncommon {
            background: $uncommon-secondary-color;
        }

        &.common {
            background: $common-secondary-color;
        }

        &.template {
            background: $template-secondary-color;
        }
    }
}

.marker {
    position: absolute;
    top: calc(var(--topOffset) * 1px);
    left: calc(var(--leftOffset) * 1px - 8px);
    width: 4px;
    height: calc(var(--clauseHeight) * 1px);
    border-radius: 15px;

    &.halfOpacity {
        opacity: 0.4;
    }

    // Marker color depending on highlight type
    &.high {
        background-color: #{$high-primary-color};
    }

    &.low {
        background-color: #{$low-primary-color};
    }

    &.medium {
        background-color: #{$medium-primary-color};
    }

    &.ok {
        background-color: #{$ok-primary-color};
    }

    &.showstopper {
        background-color: #{$showstopper-primary-color};
    }

    &.unfamiliar {
        background-color: #{$unfamiliar-primary-color};
    }

    &.uncommon {
        background-color: #{$uncommon-primary-color};
    }

    &.common {
        background-color: #{$common-primary-color};
    }

    &.template {
        background-color: #{$template-primary-color};
    }
}
