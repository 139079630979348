@use '@thought-river/ui-components/dist/colors.scss' as *;

.summary-parties {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
    padding: 5px;

    .party-wrapper {
        display: flex;
        height: 40px;
        align-items: center;
        min-width: 0;

        .party-header {
            font-size: 12px;
            min-width: 80px;
            font-weight: 600;
            color: $font-color;
        }

        .party-name-wrapper {
            min-width: 0;
            justify-content: space-between;
            display: flex;
            flex-grow: 1;
            padding: 5px;
            border-radius: 3px;
            cursor: pointer;
            align-items: flex-end;

            .party-name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: $battlegrey-10;
                font-size: 0.9rem;
            }
        }

        .edit-party-icon {
            visibility: hidden;
            font-size: 18px;
            align-self: center;
            color: $bluechaos-5;
        }

        .edit-party-link-wrapper {
            overflow: hidden;
        }

        .edit-party-link {
            text-decoration: none;
            color: $vintagevelvet-8;
            min-width: 0;

            &:hover {
                .summary-parties-tooltip {
                    display: block;
                }
            }
        }

        .edit-party-link.disabled {
            pointer-events: none;

            .edit-party-icon {
                color: $battlegrey-5;
            }
        }

        &:hover {
            .party-name-wrapper {
                background-color: $battlegrey-2;
            }

            .edit-party-icon {
                visibility: visible;
            }
        }
    }
}
