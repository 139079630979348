@use '@thought-river/ui-components/dist/colors.scss' as *;

.wrapper {
    display: flex;
    align-items: center;
}

.expand {
    background: none;
    border: none;
    padding: 0;
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-left: -4px;
    color: $font-color;

    & > svg {
        width: 100%;
        height: 100%;
    }
}
