@use '@thought-river/ui-components/dist/colors.scss' as *;

*,
*::before,
*::after {
    box-sizing: border-box;
}

.wrapper {
    min-width: 80px;
    flex-shrink: 0;
    position: relative;
    isolation: isolate;
    z-index: 10;

    // top/bottom padding shared by section bars and list
    --y-padding: 80px;
}

.sectionBarsWrapper {
    position: absolute;
    height: 100%;
    padding: var(--y-padding) 0;
}

.sectionBars {
    width: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.sectionBar {
    height: 24px;
    flex-shrink: 0;
    min-width: 4px;
    scroll-margin-top: 150px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 4px;
        background: #d0d0d0;
    }

    &.current::before {
        background: $bluechaos-7;
    }
}

.panelWrapper {
    position: absolute;
    top: 0;
    width: fit-content;
    height: 100%;

    z-index: 11;

    &.pinned {
        position: static;
        margin-right: 40px;
    }
}

.panel {
    width: 100%;
    height: 100%;
    padding: var(--y-padding) 0;
    background-color: #fff;
    border-right: 2px solid $battlegrey-4;
}

.toggle {
    z-index: 1;
    position: absolute;
    top: 20px;
    right: 2px;
    transform: translateX(50%);
    background: #ffffff;
    border: 1px solid $battlegrey-2;
}

.outline {
    height: 100%;
    padding-right: 10px;
    overflow-y: auto;
}
