@use '@thought-river/ui-components/dist/colors.scss' as *;

.file-upload-progress {
    display: flex;

    .file-type {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 15px;

        .file-type-img {
            height: 20px;
            width: auto;
        }
    }

    .progress-bar-wrapper {
        flex-grow: 1;

        .file-name-wrapper {
            display: flex;
            padding-bottom: 10px;
            position: relative;
            text-align: left;

            .file-upload-icons {
                position: absolute;
                right: 0;
                top: 0;

                .file-upload-icon {
                    font-size: 1em;
                    font-weight: bold;

                    &:not(:first-of-type) {
                        margin-left: 10px;
                    }

                    &.cancel-icon,
                    &.delete-icon {
                        cursor: pointer;

                        &.disabled {
                            cursor: not-allowed;
                            color: $battlegrey-6;
                        }
                    }

                    &.delete-icon {
                        color: $error-color;
                    }

                    &.success-icon {
                        color: $success-color;
                    }
                }
            }

            .file-name {
                flex-grow: 1;
                overflow: hidden;
                max-width: 85%;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 0;
            }
        }
    }
}
