.pane-divider {
    background: $battlegrey-5;
    min-width: 1px;
    position: relative;
    width: 2px;

    &.disabled {
        .handle {
            cursor: default;
        }
    }

    .drag-overlay {
        background: transparent;
        height: 100%;
        left: 50%;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: 800px;
        z-index: 1;
    }

    .handle {
        cursor: col-resize;
        display: inline-block;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        width: 2px;
        z-index: 1;
    }
}
