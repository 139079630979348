@use '@thought-river/ui-components/dist/colors.scss' as *;

.wrapper {
    font-family: 'Lato', sans-serif;
    padding: 42px;
    color: $font-color;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .title {
        font-weight: bold;
        font-size: 24px;
    }

    .button {
        width: fit-content;
    }

    .textFieldLabel {
        width: 105px;
    }
}
