@use '@thought-river/ui-components/dist/colors.scss' as *;

.issue-pane-description-tab {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;

    .issue-linked-clauses,
    .issue-categories {
        margin-top: 20px;

        label {
            width: 80px;
        }
    }

    .issue-notes {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        min-height: 0;
        flex-grow: 1;
    }

    .categories-multi-select {
        margin-bottom: 15px;
    }

    .issue-description-wrapper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-height: 0;

        .copy-button-wrapper {
            text-align: right;
            margin-top: 10px;
            z-index: 10;

            .btn {
                text-transform: uppercase;

                &.btn-primary {
                    &:not(:disabled) {
                        &:hover {
                            background-color: $bluechaos-5;
                        }
                    }

                    svg {
                        height: 14px;
                    }
                }
            }
        }

        .description-textarea {
            margin-top: 12px;
            flex-grow: 1;
            width: 100%;

            .MuiOutlinedInput-root {
                flex-grow: 1;
                align-items: flex-start;
                font-size: 14px;
            }
        }

        .copyable-description {
            height: 0;
            left: -9999px;
            position: absolute;
            width: 0;
            z-index: -1;
        }
    }
}
