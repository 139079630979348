@use '@thought-river/ui-components/dist/colors.scss' as *;

.wrapper {
    display: flex;
    gap: 10px;
    align-items: center;

    .select {
        width: 110px;
    }
}

.tooltip {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 2px;
}
