.issues-filters {
    &.issues-report {
        .filters-title-wrapper {
            background: $battlegrey-2;

            .filters-title {
                font-size: 1.2em;
            }
        }
    }

    .filter-select {
        margin-bottom: 2em;

        label {
            width: 80px;
        }
    }
}
