@use '@thought-river/ui-components/dist/colors.scss' as *;

.dropdown {
    display: inline-block;
    width: 225px;
    position: relative;

    &.dark,
    &.disabled.dark {
        .dropdown-label-wrapper {
            background: $maastrichtblue-5;
            border-color: $maastrichtblue-5;
            color: white;
            line-height: 26px;

            .dropdown-toggle-icon {
                color: white;
            }

            .dropdown-placeholder {
                color: white;
            }

            .dropdown-input {
                background: $maastrichtblue-5;
                color: white;

                &::placeholder {
                    color: white;
                }
            }
        }

        .dropdown-options {
            background: $maastrichtblue-5;
            color: white;
            border-color: $maastrichtblue-5;

            .dropdown-option {
                &:hover {
                    background: white;
                    color: $maastrichtblue-5;
                }

                &.clear-selection {
                    color: white;

                    &:hover {
                        color: $maastrichtblue-5;
                    }
                }
            }
        }

        &.locked {
            .dropdown-label-wrapper {
                background: $maastrichtblue-5;
            }
        }
    }

    &.locked {
        .dropdown-label-wrapper {
            background: $battlegrey-3;
            border-color: $bluechaos-5;
        }
    }

    &.disabled {
        .dropdown-label-wrapper {
            background: $battlegrey-2;
            color: $battlegrey-6;
            cursor: not-allowed;
            user-select: none;

            .dropdown-label {
                cursor: not-allowed;
            }

            .dropdown-toggle-icon {
                color: $battlegrey-6;
                cursor: not-allowed;
            }
        }
    }

    &.expanded {
        .dropdown-label-wrapper {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .dropdown-label-wrapper {
        background: white;
        border: 1px solid $battlegrey-5;
        border-radius: 4px;
        color: $battlegrey-10;
        padding: 0 8px;
        padding-right: 35px;
        position: relative;
        line-height: 31px;

        .dropdown-label {
            cursor: pointer;

            &.searchable {
                cursor: text;
            }
        }

        .dropdown-placeholder {
            color: $battlegrey-7;
        }

        .dropdown-toggle-icon {
            color: $bluechaos-5;
            cursor: pointer;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .dropdown-input {
        border: none;
        outline: none;
        padding: 0;
        width: 100%;
    }

    .dropdown-options {
        background: white;
        border: 1px solid $battlegrey-5;
        border-top: none;
        overflow-y: auto;
        position: absolute;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        box-sizing: border-box;
        max-height: 200px;
        z-index: 10;
        width: 100%;

        .dropdown-option {
            cursor: pointer;
            padding: 8px;

            &.clear-selection {
                color: $battlegrey-7;
            }

            &:hover {
                background: $bluechaos-5;
                color: white;

                .dropdown-icon {
                    color: white;
                }
            }

            > * {
                pointer-events: none;
            }
        }
    }
}
