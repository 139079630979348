@use '@thought-river/ui-components/dist/colors.scss' as *;

.button {
    visibility: visible;

    &.hideButton {
        visibility: hidden;
    }

    .icon {
        transform: rotate(-45deg);
    }
}

.popperContent {
    z-index: 999;
    background: white;
    border-radius: 4px;
    box-shadow: $button-shadow;
    color: $tertiary-button-text-color;
    display: flex;
    flex-direction: column;
    gap: 2px;

    .divider {
        height: 1px;
        width: 100%;
        margin: 4px 0;
        background-color: $vintagevelvet-2;
    }

    .listItem {
        height: 30px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        gap: 4px;

        &:hover {
            background-color: $tertiary-button-hover-color;
            cursor: pointer;
        }

        &:first-of-type {
            &::after {
                content: '';
                display: block;
                height: 5px;
            }
        }

        .unlinkIcon {
            transform: rotate(-45deg);
        }

        .icon {
            font-size: 12px;
        }
    }
}
