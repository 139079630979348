.contract-sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    min-width: $sidebar-min-width;

    .contract-sidebar-content {
        display: flex;
        flex: 1;
        min-height: 0;
        box-sizing: border-box;
        overflow: hidden;
    }

    .filters-toolbar {
        background: white;
        text-align: right;
        padding: 10px 20px;

        .filters-icon-wrapper {
            align-self: center;
            height: 24px;
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .filter-button {
                background-color: transparent;
                border: 0;
                display: flex;
                align-items: center;
                cursor: pointer;
                color: $vintagevelvet-8;

                .custom-tooltip-wrapper {
                    &.filters {
                        max-height: 24px;
                        margin-right: 1px;
                    }
                }

                .active-filters-indicator {
                    margin-left: 2px;
                }

                .filters-icon {
                    color: $bluechaos-5;
                }
            }
        }
    }

    .edit-properties-form-title {
        font-weight: bold;
        font-size: 1.1rem;
    }
}

@media only screen and (min-width: $screen-xs) {
}

@media only screen and (min-width: $screen-sm) {
}
