.party-paragraph {
    border: 1px solid transparent;
    box-sizing: border-box;
    color: $battlegrey-7;
    line-height: 1.4em;
    padding: 7.5px 30px;
    position: relative;

    .party-name {
        color: $bluechaos-5;
        cursor: pointer;
        border-radius: 10px;
        padding: 1px 3px;
        margin-left: -3px;
        margin-right: -3px;
        transition: background $time-normal ease;

        &.discarded {
            color: $orangedanger-5;

            &.highlighted {
                background-color: $orangedanger-1;
            }
        }

        &.highlighted {
            background-color: $bluechaos-1;
            z-index: 1;
        }

        &.selected {
            background-color: $bluechaos-5;
            color: white;
            user-select: none;

            &.discarded {
                background-color: $orangedanger-5;
            }

            .party-name {
                color: white;

                &.highlighted {
                    color: $battlegrey-7;
                }
            }
        }

        & + .party-name,
        & + .party-to-add {
            margin-left: 4px;
        }
    }

    .party-to-add {
        background-color: $citrussplash-2;
        color: $battlegrey-7;
        padding-bottom: 1px;
        padding-top: 1px;

        & + .party-name {
            margin-left: 0.7px;
        }
    }
}
