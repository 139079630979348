.party-sidebar {
    background: white;
    order: 1;
    position: relative;
    min-width: 100%;
    display: flex;
    flex-direction: column;

    .parties-placeholder {
        display: none;
        height: 62px;
        width: 100%;
        margin-top: 3px;
    }

    .sidebar-content {
        background: white;
        color: $battlegrey-7;
        flex: 1 1 auto;
        overflow-y: auto;
    }

    .title-wrapper {
        box-sizing: border-box;
        color: $maastrichtblue-8;
        display: flex;
        line-height: 34px;
        padding: 11px 20px;
        width: 100%;

        .title {
            font-weight: bold;
        }

        .subtitle {
            margin-left: 7.5px;
        }
    }
}

@media only screen and (min-width: $screen-sm) {
    .party-sidebar {
        min-width: $sidebar-min-width;

        .sidebar-content {
            height: 0;
        }
    }
}
